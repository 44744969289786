import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
} from '@angular/core';
import { Location } from '@angular/common';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import Swal from 'sweetalert2';
import { Subscription, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
const moment = require('moment');

import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';

// Classes
import {
  ServerSideDataSource
} from 'src/app/v2/classes/serverside-data-source';

// Models
import { Collection } from 'src/app/v2/models/collection-reference.model';
import { Company } from 'src/app/models/storage/company.model';
import { OrderBy, PaginateBy } from 'src/app/v2/models/firestore-interaction.model';
import { ProductLocation } from 'src/app/models/storage/product-location.model';
import { Rental } from 'src/app/models/storage/rental.model';
import { RentalStatus, rentalStatusGuard } from 'src/app/models/rental.model';

// Services
import { BookingService } from 'src/app/services/booking.service';
import { CurrentUserService } from '../../services/current-user.service';
import { FirestoreService } from 'src/app/v2/services/firestore.service';
import { InventoryPageService } from 'src/app/services/inventory-page.service';
import { LogService } from 'src/app/services/log.service';
import { ProductLocationService } from 'src/app/services/product-location.service';
import { RentalService } from 'src/app/services/rental.service';
import { RentalServiceV2 } from 'src/app/v2/services/rental.service';
import { SharedDataService } from 'src/app/services/shared-data.service';

@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.scss'],
})
export class BookingsComponent implements OnInit {
  private firstLast: { readonly first?: Rental, readonly last?: Rental}

  protected status: RentalStatus = RentalStatus.Current
  protected statuses = RentalStatus
  protected rentals: Rental[] = [];
  protected dataSource: ServerSideDataSource<Rental>
  protected totalRentals: number = 0
  protected paging: boolean = false
  protected readonly defaultSortField: string = 'dayStart'
  public readonly tableColumnOrder: string[] = [
    'rentalNumber',
    'name',
    'phone',
    this.defaultSortField,
    'status',
  ]

  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<Rental>;

  listen = true;
  isIFrameLoaded = false;
  protected backgroundColor: string = '#114463';
  protected textColor: string = '#FFFFFF';

  @ViewChild('bookingcostmodal', { static: false })
  bookingcostmodal: ElementRef;

  public page: number = 0;
  public inputValue: string = '';

  public buttonShow: boolean = false;

  constructor(
    private rentalService: RentalService,
    private rentalServiceV2: RentalServiceV2,
    private firestoreService: FirestoreService,
    private _afs: AngularFirestore,
    private _el: ElementRef,
    private _cdr: ChangeDetectorRef,
    private _sharedData: SharedDataService,
    public _currentUser: CurrentUserService,
    private sanitizer: DomSanitizer,
    private _logService: LogService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private currentUserService: CurrentUserService,
    private _bookingService: BookingService,
    private _inventoryPageService: InventoryPageService,
    private locationService: ProductLocationService
  ) { }

  public urlIframe: any;
  public projectId: string = environment.firebase.projectId;

  public rentalsAux;
  public lastnumberrows = 0;
  public searchNumber = '';
  public isSearch = false;
  public lastposition = 0;
  public limitperpage = 10000;
  public btnprevious = false;
  public isOpen: boolean = false;
  public btnnext = true;
  public isCurrent = true;
  public isUpcoming = false;
  public isPreparing = false;
  public isCheckOut = false;
  public isCheckIn = false;
  public isCompleted = false;
  public isCancelled = false;

  public rentalTotalPaid = 0;
  public rentalTotalCompletePaid = 0;
  public rentalSecurityPending = 0;
  public rentalAmountPendingToRefund = 0;
  public date = new Date(); //Obtain the actual date
  public today: any = new Date(
    this.date.getFullYear(),
    this.date.getMonth(),
    this.date.getDate()
  );
  public yesterday: any = new Date(
    this.date.getFullYear(),
    this.date.getMonth(),
    this.date.getDate() - 1
  );
  public tomorrow: any = new Date(
    this.date.getFullYear(),
    this.date.getMonth(),
    this.date.getDate() + 1
  );

  public locationMap: { [id: string]: ProductLocation } = {};
  public defaultLocationTimezone: string | undefined;
  public locations: ProductLocation[] = [];
  public companyObj: Company;
  public isQuickBook: boolean = false;
  public rental: Rental = {};
  public countries: any;
  private isSwalShown: boolean = false;

  protected loadingMoreProducts = false;
  protected hasFinishedProducts = false;
  protected BeginsWith = true;
  protected ContainsAny = false;

  protected urlIframeNew: any;
  protected isIFrameLoadedNew: boolean = false;
  protected templates: any = [];
  protected selectedOption: string = '';
  protected invalid: boolean = false;
  protected bookingChoices: any = {};
  protected showEditCost: boolean = false;
  protected bookingcost: number = 0;
  protected reason: string = '';
  protected invalidcost: boolean = false;
  protected invalidreason: boolean = false;
  protected spinner: boolean = false;
  protected fleetTax: number;
  protected companyTax: number;
  protected dataPI: any = {
    stripeID: '',
    paymentID: '',
    isTesting: false,
  };

  // Rxjs
  subs = new Subscription(); // group of subscriptions

  async ngOnInit(): Promise<void> {

    if (this._currentUser.currentUser.currentCompany.isRebuild === true) {
      this.buttonShow = true;
    }

    this.subscribeToLocations();

    this.companyObj = this._currentUser.currentUser.currentCompany;
    this.checkLocalhost(this.companyObj);

    this.dataSource = new ServerSideDataSource<Rental>(Collection.Rentals, this.firestoreService)
    this.dataSource.watchPageEnds().subscribe(
      firstAndLast => {
        this.firstLast = firstAndLast
        console.debug('firstLast updated', {
          firstID: firstAndLast?.first?.id,
          lastID: firstAndLast?.last?.id
        }, {firstAndLast})
      }
    )
    await this.loadBookings(this.companyObj?.id, this.status)

    this.attemptToOpenModalByBookingID();
    this.listen;

    // Handles QRcode (listens to enter button)
    $(document).ready(($) => {
      var chars = [];
      $(window).keydown((e) => {
        e.stopPropagation();
        if (this.listen) {
          if (e.which === 13) {
            var qrcode = chars.join('');
            this.qrCodeScanned(qrcode);
            chars = [];
          }
        }
      });
      $(document).keypress((e) => {
        e.stopPropagation();
        if (this.listen) {
          if (e.which >= 33 && e.which <= 122) {
            chars.push(String.fromCharCode(e.which));
          }
        }
      });
    });
    this.rentalService.companyID = this._currentUser.currentUser.companyId;
    this.rentalService.getStripePKey(this.companyObj.isTesting);
    this.getBookingTemplates();
  }

  protected getSortPropertyValueOf(rental: Rental) {
    let sortByProperty: string = 'id'
    if (!this.sort?.disabled && this.sort?.active) {
      sortByProperty = this.sort.active
    }

    if (Object.prototype.hasOwnProperty.call(rental, sortByProperty)) {
      return rental[sortByProperty]
    }
    return rental?.id
  }

  ngAfterViewInit() {
    // reset pagination after sorting
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0
      this.loadBookings(this.companyObj?.id, this.status)
    })

    this.paginator.page
      .pipe(
        tap((e: PageEvent) => {
          console.debug('page event', e)
          const page: PaginateBy = { limit: this.paginator.pageSize }

          if (!(e.pageIndex == 0) && e.previousPageIndex < e.pageIndex && this.firstLast?.last) {
            page.startAfter = this.getSortPropertyValueOf(this.firstLast.last)
          } else if (!(e.pageIndex == 0) && e.previousPageIndex || 0 > e.pageIndex && this.firstLast?.first) {
            page.endBefore = this.getSortPropertyValueOf(this.firstLast.first)
          }
          this.loadBookings(this.companyObj?.id, this.status, page)
        })
      ).subscribe()
  }

  subscribeToLocations() {
    /* Subscribe to the location collection */
    this.subs.add(
      this.locationService.getActiveAndNotActiveProductLocationsObservable(
        this._currentUser.currentUser.companyId).subscribe(async (res) => {
        this.locationMap = {}; // reset

        // populate map
        this.locations = res;
        res.forEach((loc) => {
          /* we currently do not know if we need to look for default locations yet (still in discussion) */
          if(loc.isDefault){
            this.defaultLocationTimezone = loc['timezone']; // assign default locations timezone
          }
          this.locationMap[loc.id] = loc;
        })
      })
    );
  }

  attemptToOpenModalByBookingID() {
    let id = this.activatedRoute.snapshot.paramMap.get('bookingID'); // get bookingID from URL
    if (id === null) {
      // if on bookingpage with no ID provided, returns
      return;
    }

    this.searchBookingByUrl(id); // queries for the individual booking by its ID, does processing needed to be shown in modal, shows modal
  }

  public bookingsIsOpen: boolean = false

  displayEditBooking() {
    var edit = localStorage.getItem('isEditing');
    if (edit === 'true') {
      Swal.fire({
        title: 'The booking is being edited',
        html: 'You need to save or cancel the changes before you can close this modal.',
        icon: 'error',
      });
    }
    else if(this.isQuickBook) {
      Swal.fire({
        title: 'The booking is not finished being created',
        html: 'You will lose any progress made on the booking if you close this modal.',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonColor: '#7066e0',
        cancelButtonText: 'Close without saving',
        showConfirmButton: true,
        confirmButtonColor: '#6e7881',
        confirmButtonText: 'Cancel',
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isDismissed) {
          $('#bookings-upload').modal('hide');
            this.bookingsIsOpen = false;
            this.isQuickBook = false;
        }
      });
    }
    else {
      $('#bookings-upload').modal('hide');
        this.bookingsIsOpen = false;
      // this.bookingsUpload.editDateRange = false;
    }
  }
  resetPagination() {
    this.btnprevious = true;
    this.btnnext = false;
    this.lastnumberrows = 0;
    this.lastposition = 0;
  }

  searchBookingByUrl(id) {
    // Find rental by ID == code && companyID == logged in companyID
    this._afs
      .collection('rentals')
      .ref.where('__name__', '==', id)
      .where('companyID', '==', this.currentUserService.currentUser.companyId)
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          // no matching bookingID found
          if (confirm('No booking found. Return to previous page?')) {
            this.location.back();
          }
          return;
        }

        snapshot.forEach((data) => {
          // should ever only be the size of 1
          let rental = data.data() as Rental;
          rental.id = data.id;
          this.rentalClick(rental);
          $('#bookings-upload').modal('show'); // show the modal
          return; // in case somehow there is more than one exact same doc ID for the same company.. lol
        });
      });
  }

  qrCodeScanned(code: string) {
    this._afs
      .collection('rentals')
      .doc(code)
      .ref.get()
      .then((data) => {
        let rental = data.data() as Rental;
        this.rentalClick(rental);
      });
  }

  loadIFrame(status) {
    this.isIFrameLoaded = status;
  }

  loadIFrameNew(status) {
    this.isIFrameLoadedNew = status;
  }

  ngOnDestroy() {
    this.listen = false;
    this.subs.unsubscribe();
  }

  clientCheckOut(event: any) {
    event.status = 'Client Checked Out';
    if (event.statusDate) {
      event.statusDate['Client Checked Out'] = new Date();
    }

    this._afs.collection('rentals').doc(event.id).update(event);
  }

  checkLocalhost(company: any) {
    let publicUrl = '';

    if (window.location.host.includes('localhost')) {
      publicUrl = `http://${window.location.host}/${company.companyPortal}/${company.companyURL}`;
    } else {
      publicUrl = `https://${environment.app.appDomainName}/${company.companyPortal}/${company.companyURL}`;
    }

    this.urlIframe = this.sanitizer.bypassSecurityTrustResourceUrl(publicUrl);
  }

  statusChange(selectValue: RentalStatus) {
    if (rentalStatusGuard(selectValue)) {
      this.status = selectValue
      this.loadBookings(this.companyObj?.id, this.status)
    }

  }

  clientReturn(event: any) {
    event.status = 'Booking Complete';
    if (event.statusDate) {
      event.statusDate['Booking Complete'] = new Date();
    }
    this._afs.collection('rentals').doc(event.id).update(event);
  }

  async rentalClick(rental: Rental) {
    if (!rental) {
      return;
    }
    this.spinner = true;
    this.isSwalShown = false;
    this.subs.add(this.rentalService.getRentalByID(rental.id).subscribe(
      async (rentalget: Rental) => {
        if (!this.isSwalShown) {  // Variable to only show this once
          Swal.fire({
            title: 'Loading...',
          });
          Swal.showLoading();
        }
        localStorage.setItem('isEditing', 'false');
        this.rental = Object.assign({}, rentalget);
        this.rentalService.companyID = rentalget?.companyID;

        this.fleetTax = this.companyObj.fleetTax;
        this.companyTax = this.companyObj.companyTax;
        this.dataPI = {
          stripeID: this.companyObj.stripeID,
          paymentID: rentalget.paymentID,
          isTesting: this.rentalService.isTesting,
        };

        this.rentalTotalPaid =  0;
        this.rentalTotalCompletePaid = 0;
        this.rentalSecurityPending = 0;
        this.rentalAmountPendingToRefund = 0;

        if (rentalget) {
          await this.asyncTotals(rentalget);
          this.rental.amountPendingAbs = Math.abs(
            this.rental.amountPending
          );

          this._sharedData.updateData(rentalget);
          // this.rental = rentalget;
          this.bookingsIsOpen = true;
        }
        if(!this.isSwalShown) {
          Swal.close();
          this.isSwalShown = true;
        }
        $('#bookings-upload').modal('show');
      },
      (error) => {
        console.error('Error getting rental:', error);
        if(!this.isSwalShown) {
          Swal.close();
          this.isSwalShown = true;
        }
      }
    ));
    this.countries = this.rentalService.getCountries();
  }


  async updateRentalTotals(rental) {
    await this.asyncTotals(rental);
  }

  async asyncTotals(rental: Rental) {
    this.rental = rental;
    this.spinner = true;
    this.rental.amountPendingAbs = Math.abs(this.rental.amountPending);
    this.rentalSecurityPending = 0;
    this.rentalAmountPendingToRefund = rental.amountPendingToRefund || 0;
    let totalPaid = 0;
    let totalCompletePaid = 0;
    let totalDeposit = 0;
    const mainPayments = await this.rentalService.getMainPayment(rental.id);
    mainPayments.forEach((payment) => {
      totalPaid += (payment.amount / 100);
      totalCompletePaid += (payment.amount / 100);
    });

    const charges = await this.rentalService.getCharges(rental.id);
    let completeCharges = []; // Array to get only charges with type == complete
    charges.forEach((charge) => {
      totalPaid += (charge.amount / 100);
      if (charge.type === 'complete') {
        totalCompletePaid += (charge.amount / 100);
        completeCharges.push(charge);
      }
    });

    const deposits = await this.rentalService.getDeposit(rental.id);
    deposits.forEach((deposit) => {
      if (deposit.status == 'succeeded') {
        totalPaid += (deposit.amount / 100);
      } else if (deposit.status != 'canceled') {
        totalDeposit += (deposit.amount / 100);
      }
    });
    const refunds = await this.rentalService.getRefunds(rental.id);
    refunds.forEach((charge) => {
      totalPaid -= (charge.amount / 100);
    });
    this.rentalTotalPaid = totalPaid;
    this.rentalTotalCompletePaid = totalCompletePaid;
    this.rentalSecurityPending = totalDeposit;

    this.spinner = false;
  }

  async loadBookings(companyID: string, status: RentalStatus, paginate?: PaginateBy) {
    let total: number = 0
    if (!companyID) {
      return
    }

    const sort: OrderBy[] = []
    if (!this.sort?.disabled && this.sort?.active && this.sort?.direction != '') {
      console.debug(`loadBookings(${companyID}, '${status}') sort=${!this.sort.disabled}`, this.sort.active, this.sort.direction)
      sort.push({field: this.sort.active, direction: this.sort.direction})
    } else if (this.defaultSortField) {
      sort.push({field: this.defaultSortField, direction: 'asc'})
    }

    const conditions = this.rentalServiceV2.makeConditionsForFind(companyID, status)
    if (!paginate?.startAfter && !paginate?.endBefore) {
      total = await this.dataSource.total(conditions)
      this.totalRentals = total
      this.paginator.pageIndex = 0
    }

    this.dataSource.load(conditions, sort, paginate)
  }

  chageTypeSearch() {
    this.BeginsWith =
      this.BeginsWith === true
        ? false
        : this.BeginsWith === false
          ? true
          : true;
    this.ContainsAny =
      this.ContainsAny === true
        ? false
        : this.ContainsAny === false
          ? true
          : true;
  }


  searchBooking() {
    this.page = 0;
    Swal.fire({
      title: 'Searching booking',
    });
    Swal.showLoading();
    this.isSearch = true;

    if (this.searchNumber.length === 0) {
      this.resetSearch();
    } else {
      this.loadingMoreProducts = false;
      this.hasFinishedProducts = false;

      const searchNumber = this.searchNumber.toString().toLowerCase();
      const variant = searchNumber.split(' ');

      const searchType = this.BeginsWith
        ? this.rentalService.getBookingsSearchTermBegins(variant)
        : this.rentalService.getBookingsSearchTermContains(variant);

      searchType.subscribe((Rentals) => {
        this.resetPagination();
        this.rentalsAux = [];
        this.rentals = [];
        Rentals.forEach((R) => {
          this.rentalService
            .getBookingByID(R.payload.doc.data().id)
            .subscribe((newRentals) => {
              //filtra y elimina donde isTest: true o esta indefinido
              newRentals.forEach((element: any) => {
                this.rentals.push({
                  id: element.payload.doc.id,
                  ...element.payload.doc.data(),
                });

                let NoDuplicates = this.removeDuplicateObjects(
                  this.rentals,
                  'rentalNumber'
                );

                this.rentals = NoDuplicates;

                this.rentalsAux.push({
                  id: element.payload.doc.id,
                  ...element.payload.doc.data(),
                });

                let NoDuplicatesAux = this.removeDuplicateObjects(
                  this.rentalsAux,
                  'rentalNumber'
                );

                this.rentalsAux = NoDuplicatesAux;
              });
              Swal.close();
            });
        });
        if (this.rentals.length == 0) {
          Swal.close();
        }
      });
    }
  }

  clearInput() {
    this.searchNumber = '';
    this.inputValue = '';
  }

  resetSearch() {
    Swal.fire({
      title: 'Searching booking',
    });
    Swal.showLoading();
    this.isSearch = false;
    this.searchNumber = '';
    Swal.close();
  }

  replaceRental($event: Rental){
    this.rental = $event;
  }

  removeDuplicateObjects(arr, prop) {
    return arr.filter(
      (obj, index, self) =>
        index === self.findIndex((el) => el[prop] === obj[prop])
    );
  }


  async checkFirstLog(lastrental) {
    const firstlog = await this._logService.searchFirstLogCollection(
      lastrental.id,
      true
    );
    if (firstlog.length == 0) {
      let logaddon = {
        type: 'pdfRental',
        details: 'Original Rental Saved',
        change: 'first',
        rental: lastrental,
        timestamp: new Date(),
        firstName: this._currentUser.currentUser
          ? this._currentUser.currentUser.firstName
          : 'public',
        lastName: this._currentUser.currentUser
          ? this._currentUser.currentUser.lastName
          : 'public',
        userID: this._currentUser.currentUser
          ? this._currentUser.currentUser.id
          : 'public',
      };
      this._logService.addRentalPDFLog(this.rental.id, logaddon);
    }
  }

  async getBookingTemplates() {
    this.selectedOption = '';
    this.bookingChoices = {};
    this.templates = await this._bookingService.getTemplatesForCompany();
    // Get the updated bookingChoice
    const choice = await this._currentUser.getBookingChoice();
    this.bookingChoices = choice;
    let value = this.bookingChoices.get(this._currentUser.currentUser.companyId);
    this.selectedOption = value ? value : '';
  }

  async showSelector() {
    $('#popupModal').modal('show');
  }

  closeShowSelector() {
    $('#popupModal').modal('hide');
  }

  openNewBookingIframe() {
    if (this.selectedOption === '') {
      this.invalid = true;
      return;
    }

    this.isIFrameLoadedNew = true;
    let publicUrl = '';
    this.invalid = false;

    if (window.location.host.includes('localhost')) {
      publicUrl = `http://${window.location.host}/book/${this.selectedOption}/catalog`;
    } else {
      publicUrl = `https://${environment.app.appDomainName}/book/${this.selectedOption}/catalog`;
    }

    this.urlIframeNew = this.sanitizer.bypassSecurityTrustResourceUrl(publicUrl);

    const bookingChoice = {
      [this._currentUser.currentUser.companyId]: this.selectedOption
    };

    this.updateUserBookingChoice(bookingChoice);

    $('#popupModal').modal('hide');
  }

  openNewBookingNewTab() {
    if (this.selectedOption === '') {
      this.invalid = true;
      return;
    }

    this.invalid = false;

    if (window.location.host.includes('localhost')) {
      window.open(`http://${window.location.host}/book/${this.selectedOption}/catalog`, '_blank');
    } else {
      window.open(`https://${environment.app.appDomainName}/book/${this.selectedOption}/catalog`, '_blank');
    }

    const bookingChoice = {
      [this._currentUser.currentUser.companyId]: this.selectedOption
    };

    this.updateUserBookingChoice(bookingChoice);

    $('#popupModal').modal('hide');

  }

  updateUserBookingChoice(newBookingChoices: any) {
    let keys = Object.keys(newBookingChoices);
    if (keys[0] === this._currentUser.currentUser.companyId) {
      this.bookingChoices.set(keys[0], this.selectedOption);
      this._currentUser.updateUserBookingChoice(this._currentUser.currentUser.id, Object.fromEntries(this.bookingChoices));
    }
  }

  async openQuickBooking() {
    this.rental = {}
    this.rentalTotalPaid = 0;
    this.rentalTotalCompletePaid = 0;
    this.rentalSecurityPending = 0;
    this.rentalAmountPendingToRefund = 0;

    this.countries = this.rentalService.getCountries();
    $('#popupModal').modal('hide');
    this.bookingsIsOpen = true;
    this.isQuickBook = true;
    $('#bookings-upload').modal('show');

  }

  isQuickBookChange($event){
    this.isQuickBook = $event;
  }

}
