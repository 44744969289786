import { Injectable } from '@angular/core';
import { CurrentUserService } from './current-user.service';
import { ProductsService } from './products.service';
import { Cart } from 'src/app/models/storage/cart.model';
import { Company } from 'src/app/models/storage/company.model';
import { environment } from 'src/environments/environment';
import { TipElementInterface, WidgetInterface } from 'src/app/models/widget.model';

@Injectable({
  providedIn: 'root',
})
export class PricingService {
  constructor(
    private _currentUserService: CurrentUserService,
    private _productService: ProductsService,
  ) {}

  /**
   *
   * @param widgetList
   * @param daySpan
   * @param selectedHours
   * @param productGroup
   * @param isCartWidget
   * @param rentalPriceWithNoCartWidgets // this is the price of all of the products totals + their addons totals in the cart (excludes all cart widgets) // THIS WILL ONLY BE NEEDED IF THERE IS A CART TIP WIDGET
   * @param excludeTipWidget // determines if the addonPrice returned includes the tip price calculated in: if excludeTipWidget = true then the tip price is not included in the returned addonPrice
   * @returns the sum of the prices of all the addons in the widgetList
   */
  calculateAddonPrice(
    widgetList,
    daySpan,
    selectedHours,
    productGroup,
    isCartWidget,
    rentalPriceWithNoCartWidgets?,
    excludeTipWidget?
  ) {
    excludeTipWidget = excludeTipWidget || false;
    let addonPrice = 0;
    let totalTipWidgetsPercentage = 0;
    rentalPriceWithNoCartWidgets = rentalPriceWithNoCartWidgets || 0;
    widgetList.forEach((widget) => {
      const isRecurringPrice = widget?.element?.recurringPrice || false;

      // If it is a static price
      if (!isRecurringPrice) {
        switch (widget.widgetType) {
          case 'dropdown':
          case 'radios':
            widget['element']['options'].forEach((choice, index) => {
              if (choice.is_selected) {
                if (choice?.price) {
                  // Check if the choice has a price (sometimes - doesn't)
                  addonPrice += Number(choice.price);
                }
              }
            });
            break;

          case 'checkbox':
            if (widget['element']['inputValue'] && widget['element']['price']) {
              addonPrice += Number(widget['element']['price']) || 0;
            }
            break;

          case 'price':
            if (widget['element']['price']) {
              addonPrice += Number(widget['element']['price']) || 0;
            }
            break;

          case 'quantity':
            if (widget['element']['inputValue'] && widget['element']['price']) {
              addonPrice += Number(
                widget['element']['price'] * widget['element']['inputValue']
              ) || 0;
            }
            break;

          case 'product':
            let sudoInput = 0;
            if (widget['element']['isDropdown']) {
              widget['element']['options'].forEach((choice, index) => {
                if (choice.is_selected && choice.price) {
                  addonPrice += Number(choice.price) || 0;
                }
              });
            } else {
              if (widget['element']['options']) {
                widget['element']['options'].forEach((option) => {
                  if (option.inputValue) {
                    sudoInput += Number(option.inputValue) || 0;
                  }
                });
              }
            }
            if (widget['element']['price']) {
              addonPrice += Number(widget['element']['price'] * sudoInput) || 0;
            }
            break;

          case 'tip':
            if (widget['element']['price']) {
              totalTipWidgetsPercentage += Number(widget['element']['price']) || 0;
            }
            break;

          } // Switch end
      }
      // If it is a recurring price
      else {
        if (selectedHours == '24 Hour Rental') {
          daySpan = daySpan - 1;
        }
        switch (widget.widgetType) {
          case 'dropdown':
          case 'radios':
            widget['element']['options'].forEach((choice, index) => {
              if (choice.is_selected) {
                addonPrice += Number(choice['price'] * daySpan) || 0;
              }
            });
            break;

          case 'checkbox':
            if (widget['element']['inputValue']) {
              addonPrice += Number(widget['element']['price'] * daySpan) || 0;
            }
            break;

          case 'price':
            if (widget['element']['price']) {
              addonPrice += Number(widget['element']['price'] * daySpan) || 0;
            }
            break;

          case 'quantity':
            if (widget['element']['inputValue']) {
              addonPrice += Number(
                widget['element']['price'] *
                  daySpan *
                  widget['element']['inputValue']
              ) || 0;
            }
            break;

          case 'product':
            let sudoInput = 0;
            if (widget['element']['isDropdown']) {
              widget['element']['options'].forEach((choice, index)  => {
                if (choice.is_selected && choice.price) {
                  addonPrice += Number(choice.price * daySpan) || 0;
                }
              })
            } else {
              if (widget['element']['options']) {
                widget['element']['options'].forEach((option) => {
                  if (option.inputValue) {
                    sudoInput += Number(option.inputValue) || 0;
                  }
                });
              }
            }
            if (widget['element']['price']) {
              addonPrice += Number(widget['element']['price'] * sudoInput) || 0;
            }
            break;
        } // Switch end
      }
    });

    if (totalTipWidgetsPercentage > 0 && !excludeTipWidget) {
      if (isCartWidget) {
        let subTotal = rentalPriceWithNoCartWidgets + addonPrice;
        addonPrice += this.calculateTipPrice(totalTipWidgetsPercentage, subTotal);
      }
      else {
        let individualRentalPrice = this.calculateIndividualRentalPrice(daySpan, selectedHours, productGroup).rentalPrice
        let subTotal = individualRentalPrice + addonPrice;
        addonPrice += this.calculateTipPrice(totalTipWidgetsPercentage, subTotal);
      }
    }
    return addonPrice;
  }

  // cart rental price is the price of everything in the cart excluding the tip widget
  // individual rental price is the price of a specific product (this is if the tip widget isnt a cart widget)
  calculateTipPrice(tipWidgetsPrice, subtotalPrice) {
    let tipAddonPrice = 0;
    tipAddonPrice += subtotalPrice * (tipWidgetsPrice / 100);
    return tipAddonPrice || 0;
  }

  calculateIndividualRentalPrice(daySpan, selectedHours, productGroup, item?) {
    let rentalPrice = 0;
    let singlePrice = 0;

    // Item will only be passed in for mini-cart cases, an item can only get the variable "adjustedPrice" from the mini-cart
    if (item && item['adjustedPrice']) {
      rentalPrice = item['adjustedPrice'];
      singlePrice = item['adjustedPrice'];
    } else {
      // Use by day price
      if (
        selectedHours == 'All Day Rental' ||
        selectedHours == '24 Hour Rental'
      ) {
        if (selectedHours == '24 Hour Rental' && daySpan > 1) {
            daySpan = daySpan - 1;
        }
        // Day loop
        let ind = 0;
        let currentIncrement = 0;
        if(productGroup){
          singlePrice = productGroup['priceByDay'][ind]['price'];
          for (let i = 0; i <= daySpan; i++) {
            // If there is a day in the object equal to the day were counting, set the price and new increment
            // Then move the index up by one to look at the next vlaue in obejct
            if (i == productGroup['priceByDay'][ind]['day']) {
              currentIncrement = productGroup['priceByDay'][ind]['increment'];
              rentalPrice = productGroup['priceByDay'][ind]['price'];
              // If there is another value in the object
              if (ind + 1 <= productGroup['priceByDay'].length - 1) {
                ind += 1;
              }
            }
            // Otherwise continue to add the increment
            else {
              rentalPrice += currentIncrement;
            }
          }
        }

      }
      // If it is an hourly rental
      else {
        productGroup['priceByHour'].forEach((obj) => {
          if (obj['hour'] == Number(selectedHours)) {
            rentalPrice = obj['price'];
            singlePrice = obj['price'];
          }
        });
      }
    }
    return { rentalPrice, singlePrice };
  }

  roundDecimals(value: number | string, decimals: number): number {
    let num = Number(value); // Convert string to number

    if (isNaN(num)) {
      return 0; // Return 0 if its not a valid number
    }

    const factor = Math.pow(10, decimals);
    return Math.round(num * factor) / factor;
  }

  async calculateCartTotal(cartObj: Cart, prodGroupMap, discountObj) {
    let 
      cartItemsTotal = 0, // All items + widgets total
      cartWidgetsTotal = 0, // All cart widgets + widgets total
      feeTotal = 0, // Fleet Tax Items + cart Widgets Total
      promoCodeDiscountTotal = 0, // Promo Discount of (items + widget items + cart widgets)
      tipWidgetTotal = 0, // Tip widget total of all total
      taxTotal = 0, // Company Tax of all total including tip widget
      subTotal = 0

    const companyId = this.getCompanyIdFromCartObj(cartObj)
    if (companyId == "") {
      return {
        cartItemsTotal,
        cartWidgetsTotal,
        feeTotal,
        promoCodeDiscountTotal,
        tipWidgetTotal,
        taxTotal,
        subTotal
      };
    }

    const company: Company = await this._currentUserService.getCompanyInfoByID(companyId)
    if (!company) {
      return {
        cartItemsTotal,
        cartWidgetsTotal,
        feeTotal,
        promoCodeDiscountTotal,
        tipWidgetTotal,
        taxTotal,
        subTotal
      };
    }

    const companyTax = company['companyTax'] / 100;
    const fleetTax = company['fleetTax'] / 100;
    let cartTipPrice = 0;
    let discountType = "";

    for (const item of cartObj['items']) {
      // Calls addon calculation function
      let itemAddonPriceResult = this.calculateAddonPrice(
        item['widgetList'],
        item['daySpan'],
        item['selectedHours'],
        prodGroupMap[item['parentId']],
        false
      );

      if (isNaN(itemAddonPriceResult)) {
        itemAddonPriceResult = 0;
        console.error('Error calculating cartAddonPrice')
      }
      cartItemsTotal += itemAddonPriceResult;
      //cartAddonPrice += itemAddonPriceResult;


      // Calls rental calculation function
      let cartRentalPriceResult = this.calculateIndividualRentalPrice(
        item['daySpan'],
        item['selectedHours'],
        prodGroupMap[item['parentId']],
        item
      ).rentalPrice;

      if (isNaN(cartRentalPriceResult)) {
        cartRentalPriceResult = 0;
        console.error('Error calculating cartRentalPrice')
      }
      cartItemsTotal += cartRentalPriceResult;
      //cartRentalPrice += cartRentalPriceResult;
    }

    // Check if cartWidgetList has cart Widgets
    if (cartObj.cartWidgetList && cartObj.cartWidgetList.length > 0) {
      let cartWidgetPriceResult = this.calculateAddonPrice(
        cartObj.cartWidgetList,
        cartObj['items'][0]['daySpan'],
        cartObj['items'][0]['selectedHours'],
        null,
        true,
        cartItemsTotal,
        true
      );
      if (isNaN(cartWidgetPriceResult)) {
        cartWidgetPriceResult = 0;
        console.error('Error calculating cartWidgetPrice')
      }
      cartWidgetsTotal += cartWidgetPriceResult;

      // Get cartWidget tip
      const tipWidget: WidgetInterface & { element: TipElementInterface } = cartObj.cartWidgetList.find(widget => widget.widgetType === "tip");
      
      if (tipWidget && tipWidget.element && tipWidget.element.price) {
        const tipPrice = Number(tipWidget.element.price);

        if (!isNaN(tipPrice)) {
          cartTipPrice = tipPrice;
          console.log(cartTipPrice)
        }
      }
    }

    subTotal = this.roundDecimals((cartItemsTotal + cartWidgetsTotal),2); // items + item widgets + cart widgets
    
    if (discountObj !== null) {
      console.log(discountObj)
      if (discountObj.discountScope === 'rental') {
        discountType = "rental";
        if (discountObj.discountType === 'percentage') {
          promoCodeDiscountTotal = (discountObj.discount / 100) * subTotal;
        } else if (discountObj.discountType === 'fixedAmount') {
          promoCodeDiscountTotal = discountObj.discount;
        }
      } else {
        discountType = 'product'
      }
    }
    let subtotalwithdiscount = this.roundDecimals((subTotal - promoCodeDiscountTotal),2);
    feeTotal = this.roundDecimals((subtotalwithdiscount * fleetTax),2); //calculate fleettax price
    let feeDiscountSubTotal = this.roundDecimals((subtotalwithdiscount + feeTotal),2);
    if (cartTipPrice > 0) {
      tipWidgetTotal = this.roundDecimals((subtotalwithdiscount * (cartTipPrice / 100)),2)
    }
    taxTotal = this.roundDecimals((subtotalwithdiscount * companyTax),2);
    promoCodeDiscountTotal * -1; // Convert the value to a negative number
    return {
      cartItemsTotal,
      cartWidgetsTotal,
      feeTotal,
      promoCodeDiscountTotal,
      tipWidgetTotal,
      taxTotal,
      subTotal
    };
  }
  getAmountWithoutTaxes(amount: number): number {
    try {
      const company = this._currentUserService.currentUser.currentCompany;

      if (!company || company.fleetTax == null || company.companyTax == null) {
        throw new Error('Company information is not available');
      }
        if (amount < 0) {
        throw new Error('The amount cannot be less than zero');
      }

      const taxes = (company.fleetTax + company.companyTax) / 100;
      const amountWoTaxes = amount / (1 + taxes);
        return this.roundDecimals(amountWoTaxes,2);

    } catch (error) {
      console.error('Error calculating the amount without taxes:', error);
      return 0;
    }
  }

  async calculateReservationTotal(cartObj: Cart): Promise<{ reservationSubtotal: number, taxesAndFees: number, fleetTax: number, companyTax: number }> {
    let subtotal = 0;
    let taxes = 0;
    if (cartObj.items && cartObj.items.length === 0) {
      return { reservationSubtotal: subtotal, taxesAndFees: 0, fleetTax:  0, companyTax: 0 };
    }

    const promises = cartObj.items.map(async item => {
      const productGroup = await this._productService.getProductGroupById(item.parentId);
      const categoryID = productGroup.categoryID ?? null;
      if (categoryID && environment.hardCodedFeatures.reservationCategoryIDs.includes(categoryID)) {
        subtotal += 100; //if there is a category, the reservation value should be $100
      } else {
        subtotal += 50; //if not, should be $50
      }
    });

    await Promise.all(promises);
    const {finalAmount, fleetTax, companyTax} = await this.addTaxAndFeesToAmount(cartObj, subtotal)
    taxes = (finalAmount - subtotal); //Calcuate taxes

    return { reservationSubtotal: subtotal, taxesAndFees: taxes, fleetTax:  fleetTax, companyTax: companyTax};
  }

  // retrieve companyId from cart, and log warn if missing
  private getCompanyIdFromCartObj(cartObj: Cart) : string {
    let companyID = cartObj.companyId;
    if (companyID) {
      return companyID
    }
    const cartValue = cartObj.id || cartObj.dateCreated || cartObj.dateCheckedOut || "none"
    const length = cartObj.items.length
    console.warn(`cart object (${cartValue}, total items=${length}) is missing companyId`)
    return ""
  }

  /**
   * addTaxAndFeesToAmount is used with a partial payments, reservations,
   * to calculate taxes on just the reservation amount.
   */
  async addTaxAndFeesToAmount(cartObj: Cart, amount: number) {
    let companyTax: number = 0,
        fleetTax: number = 0,
        finalAmount: number = 0;
    const companyId = this.getCompanyIdFromCartObj(cartObj)
    if (companyId == "") {
      return {finalAmount, companyTax, fleetTax};
    }

    const company: Company = await this._currentUserService.getCompanyInfoByID(companyId)
    if (!company) {
      return {finalAmount, companyTax, fleetTax};
    }

    const companyTaxPercentage = company['companyTax'] / 100 || 0;
    const fleetTaxPercentage = company['fleetTax'] / 100 || 0;

    companyTax += (Number(amount) * companyTaxPercentage) || 0;
    fleetTax += (Number(amount) * fleetTaxPercentage) || 0;

    finalAmount = amount + companyTax + fleetTax;
    return {finalAmount, companyTax, fleetTax};
  }

  async calculateAddonTaxAndFees(cartObj: Cart, widget, daySpan, selectedHours) {
    let taxTotal = 0;
    let feeTotal = 0;

    const companyId = await this.getCompanyIdFromCartObj(cartObj)
    if (companyId == "") {
      return { taxTotal, feeTotal }
    }

    const company: Company = await this._currentUserService.getCompanyInfoByID(companyId)
    if (!company) {
      return { taxTotal, feeTotal }
    }
    if (selectedHours == '24 Hour Rental') {
      daySpan = daySpan - 1;
    }

    const companyTax = company['companyTax'] / 100 || 0;
    const fleetTax = company['fleetTax'] / 100 || 0;
    const isRecurringPrice = widget?.element?.recurringPrice || false;

    switch (widget.widgetType) {
      case 'dropdown':
      case 'radios':
        widget['element']['options'].forEach((choice) => {
          if (choice?.is_selected) {
            if (isRecurringPrice) {
              taxTotal += (Number(choice.price) * daySpan * companyTax) || 0;
              feeTotal += (Number(choice.price) * daySpan * fleetTax) || 0;
            } else {
              taxTotal += (Number(choice.price) * companyTax) || 0;
              feeTotal += (Number(choice.price) * fleetTax) || 0;
            }
          }
        });
        break;

      case 'checkbox':
        if (widget['element']['inputValue']) {
          if (isRecurringPrice) {
            taxTotal += (Number(widget['element']['price']) * daySpan * companyTax) || 0;
            feeTotal += (Number(widget['element']['price']) * daySpan * fleetTax) || 0;
          } else {
            taxTotal += (Number(widget['element']['price']) * companyTax) || 0;
            feeTotal += (Number(widget['element']['price']) * fleetTax) || 0;
          }
        }
        break;

      case 'price':
        if (widget['element']['price']) {
          if (isRecurringPrice) {
            taxTotal += (Number(widget['element']['price']) * daySpan * companyTax) || 0;
            feeTotal += (Number(widget['element']['price']) * daySpan * fleetTax) || 0;
          } else {
            taxTotal += (Number(widget['element']['price']) * companyTax) || 0;
            feeTotal += (Number(widget['element']['price']) * fleetTax) || 0;
          }
        }
        break;

      case 'quantity':
        if (widget['element']['inputValue']) {
          if (isRecurringPrice) {
            taxTotal += (Number(widget['element']['price'] * widget['element']['inputValue']) * daySpan * companyTax) || 0;
            feeTotal += (Number(widget['element']['price'] * widget['element']['inputValue']) * daySpan * fleetTax) || 0;
          } else {
            taxTotal += (Number(widget['element']['price'] * widget['element']['inputValue']) * companyTax) || 0;
            feeTotal += (Number(widget['element']['price'] * widget['element']['inputValue']) * fleetTax) || 0;
          }
        }
        break;

      case 'product':
        let sudoInput = 0;
        if (widget['element']['isDropdown']) {
          widget['element']['options'].forEach((choice) => {
            if (choice.is_selected) {
              if (isRecurringPrice) {
                taxTotal += (Number(choice.price) * daySpan * companyTax) || 0;
                feeTotal += (Number(choice.price) * daySpan * fleetTax) || 0;
              } else {
                taxTotal += (Number(choice.price) * companyTax) || 0;
                feeTotal += (Number(choice.price) * fleetTax) || 0;
              }
            }
          });
        } else {
          if (widget['element']['options']) {
            widget['element']['options'].forEach((option) => {
              if (option.inputValue) {
                sudoInput += Number(option.inputValue) || 0;
              }
            });
          }
        }
        if (widget['element']['price']) {
          if (isRecurringPrice) {
            taxTotal += (Number(widget['element']['price']) * daySpan * companyTax * sudoInput) || 0;
            feeTotal += (Number(widget['element']['price']) * daySpan * fleetTax * sudoInput) || 0;
          } else {
            taxTotal += (Number(widget['element']['price']) * companyTax * sudoInput) || 0;
            feeTotal += (Number(widget['element']['price']) * fleetTax * sudoInput) || 0;
          }
        }
        break;

    }
    taxTotal = this.roundDecimals(taxTotal,2)
    feeTotal = this.roundDecimals(feeTotal,2)
    return { taxTotal, feeTotal };
  }
} // End of service
