import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

import { map } from 'rxjs/operators';
import * as ccsJSON from 'countrycitystatejson';

import {
  Options,
  DatesSelected,
} from 'src/app/models/rental.model';
import { ProductV0 } from 'src/app/models/storage/product.model';
import { Categories } from 'src/app/models/storage/categories.model';
import { Rental } from 'src/app/models/storage/rental.model';
import { stripeTransaction } from 'src/app/models/storage/stripe.model';
import { Company } from 'src/app/models/storage/company.model';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';

import { StorageService } from './storage.service';
import { CurrentUserService } from './current-user.service';
import * as moment from 'moment-timezone';
import { ProductLocation } from 'src/app/models/storage/product-location.model';

import firebase from 'firebase/compat/app';
import 'firebase/firestore';
import { ProductToday } from 'src/app/models/report-sales.model';
import { environment } from 'src/environments/environment';

moment.tz.setDefault('America/Denver');

@Injectable({
  providedIn: 'root',
})
export class RentalService {
  isTesting: boolean;
  stripePublicKey: any;
  timezone = 'America/Denver';
  timezoneName = 'MDT';
  stripePublicKeyConstant: any;
  isTimeSelected: boolean = false;
  isAllowedByHourSelected: boolean = false;
  timeOptionSelected: number;
  sendEmail: boolean = false;
  isCompanyEmail: boolean = false;
  companyEmail: string = '';
  currentRentalID: string = '';

  get searching(): boolean {
    return this._searching;
  }

  set searching(value: boolean) {
    this._searching = value;
  }

  get datesSelected(): DatesSelected {
    return this._datesSelected;
  }

  set datesSelected(value: DatesSelected) {
    this._datesSelected = value;
    this.$emitter.emit('datesChanged');
  }

  get categories(): Categories[] {
    return this._categories;
  }

  set categories(value: Categories[]) {
    this._categories = value;
  }

  get options(): Options[] {
    return this._options;
  }

  set options(value: Options[]) {
    this._options = value;
  }

  // Public Variables TODO RapidCycling
  get optionID(): string {
    return this._optionID;
  }

  set optionID(value: string) {
    this._optionID = value;
    this.$emitter.emit('optionChanged');
  }

  set categoryID(value: string) {
    this._categoryID = value;
    this.$emitter.emit('categoryChanged');
  }

  get categoryID(): string {
    return this._categoryID;
  }

  get products(): ProductV0[] {
    return this._products;
  }

  get rental(): Rental {
    return this._rental;
  }

  set rental(value: Rental) {
    this._rental = value;
    this.$emitter.emit('cartChanged');
  }

  set products(value: ProductV0[]) {
    this._products = value;
    localStorage.setItem('product', JSON.stringify(this._products));
    this.$emitter.emit('productAdded');
  }
  public date = new Date(); //Obtain the actual date

  public isCart: boolean = false;
  //start on 00:00:00 and end on 23:59:59
  public _datesSelected: DatesSelected = {
    dateStart: moment.tz(this.date, this.timezone).startOf('day').toDate(),
    dateEnd: moment.tz(this.date, this.timezone).endOf('day').toDate(),
  };
  public lastOptionSelected: string = '';
  private _optionID: string = '';
  private _categoryID: string = '';
  public canprevious = true;
  public cannext = true;
  private _options: Options[] = [];
  private _products: ProductV0[] = [];
  private _categories: Categories[] = [];
  private _rental: Rental = null;
  private _searching: boolean = false;
  public today: any = new Date(
    this.date.getFullYear(),
    this.date.getMonth(),
    this.date.getDate()
  );
  public today2: any =
    new Date(
      this.date.getFullYear(),
      this.date.getMonth(),
      this.date.getDate()
    ).getTime() / 1000;
  $emitter = new EventEmitter<any>();
  public paymentIntent: any;
  public isStepOne: boolean = true;
  public isOptionSelected: boolean = false;
  public isCategorySelected: boolean = false;
  public isProductSelected: boolean = false;
  public optionSelected: string;
  public companyPortal = '';
  public companyName = '';
  public companyURL = '';
  public companyID = '';
  public company: any;
  public defaultLocation: ProductLocation;
  public defaultLocationID: string;
  public stripeID: string = '';
  public stripeIDConstant = '';
  public fleetTax = 0.0;
  public companyTax = 0.0;
  public optionName: string;
  public rentalType: string;
  public startTime: any;
  public endTime: any;
  public days: number;
  public isAllDay: boolean = false;
  public hours: number;
  public interval: number = 30;
  public intervalType: string = 'minutes';
  public isWaiver: boolean = false;
  public isTestPayment: boolean = false;
  public isDeveloper: boolean = false;

  public currentRental: any[] = [];

  $subscription: Subscription = new Subscription();

  private productsCollection: AngularFirestoreCollection<any>;

  // Create a BehaviorSubject of type string
  public myVariable$ = new BehaviorSubject<string>('');

  constructor(
    private afs: AngularFirestore,
    private _storageService: StorageService,
    private _currentUserService: CurrentUserService
  ) {
    this.productsCollection = this.afs.collection<any>('rentals');
  }

  getCompanyByTitle(title): Promise<Company> {
    return this.afs
      .collection('companies')
      .ref.where('companyName', '==', title)
      .get()
      .then((docs) => {
        if (docs.size === 1) {
          let pre = docs.docs[0].data() as Company;
          pre.id = docs.docs[0].id;
          return pre;
        } else {
          return null;
        }
      });
  }

  getCompanyByCompanyURL(companyURL): Promise<Company> {
    return this.afs
      .collection('companies')
      .ref.where('companyURL', '==', companyURL)
      .get()
      .then((docs) => {
        if (docs.size === 1) {
          let pre = docs.docs[0].data() as Company;
          pre.id = docs.docs[0].id;
          return pre;
        } else {
          return null;
        }
      });
  }

  getDefaultLocation(productLocationID): Promise<ProductLocation> {
    return this.afs
      .collection('productLocations')
      .doc(productLocationID)
      .ref.get()
      .then((doc) => {
        const data = doc.data() as ProductLocation;
        return data;
      });
  }

  isCompanyTesting(companyURL) {
    return this.afs
      .collection('companies', (ref) =>
        ref.where('companyURL', '==', companyURL)
      )
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((data) => {
            let company = data.payload.doc.data() as Company;
            company.id = data.payload.doc.id;
            return company.isTesting;
          });
        })
      );
  }

  getCompanyByID(companyID: string): Promise<Company> {
    return this.afs
      .collection('companies')
      .doc(companyID)
      .ref.get()
      .then((doc) => {
        const data = doc.data() as Company;
        data.id = doc.id;
        return data;
      });
  }
  getCompanySubscription(companyID: string): Observable<Company> {
    return this.afs
      .collection('companies')
      .doc(companyID)
      .valueChanges()
      .pipe(
        map((data) => {
          return data as Company;
        })
      );
  }

  checkCategories(companyID: string): Promise<number> {
    return this.afs
      .collection('rentalCategories', (ref) =>
        ref.where('companyID', '==', companyID)
      )
      .ref.get()
      .then((docs) => {
        return docs.size;
      });
  }
  checkProducts(companyID: string): Promise<number> {
    return this.afs
      .collection('products', (ref) => ref.where('companyID', '==', companyID))
      .ref.get()
      .then((docs) => {
        return docs.size;
      });
  }

  updateCompany(company) {
    this.afs.collection('companies').doc(company.id).update({
      companyWaiver: company.companyWaiver,
    });
  }
  updateAmountPending(rentalID:string, amount: number) {
    this.afs.collection('rentals').doc(rentalID).update({
      amountPending: amount,
    });
  }
  addReservationPaymentDate(rentalID: string) {
    this.afs.collection('rentals').doc(rentalID).update({
      'statusDate.reservationPayment': new Date()
    }).then(() => {
      console.log('Reservation payment date added/updated successfully');
    }).catch((error) => {
      console.error('Error updating reservation payment date: ', error);
    });
  }

  updateAllCompany(company) {
    this.afs.collection('companies').doc(company.id).update(company);
  }
  updateCompanyWaivers(company, waiver, id) {
    this.afs
      .collection('companies')
      .doc(company.id)
      .collection('waivers')
      .doc(id)
      .update(waiver);
  }
  createWaiver(company, waiver) {
    return this.afs
      .collection('companies')
      .doc(company.id)
      .collection('waivers')
      .add(waiver)
      .then((docRef) => {
        return docRef.id;
      })
      .catch((error) => console.error('Error adding document: ', error));
  }
  deleteWaiver(company, waiver) {
    this.afs
      .collection('companies')
      .doc(company.id)
      .collection('waivers')
      .doc(waiver.id)
      .delete();
  }
  searchAvailabilityByWaiver(company, waiver) {
    let query = this.afs
      .collection('products')
      .ref.where('waivers', 'array-contains', waiver.id)
      .where('companyID', '==', company.id);
    return query.get().then((docs) => {
      return docs.docs.map((doc) => {
        let data = doc.data() as any;
        data.id = doc.id;
        return data;
      });
    });
  }
  getCompanyInfo() {
    return this.afs
      .collection('companies')
      .doc(this._currentUserService.currentUser.companyId)
      .ref.get()
      .then((doc) => {
        const data = doc.data() as Company;
        data.id = doc.id;
        return data;
      });
  }

  getCompanyInfoByStripeId(stripeID: string) {
    return this.afs
      .collection('companies')
      .ref.where('stripeID', '==', stripeID)
      .get()
      .then((docs) => {
        let pre = docs.docs[0].data() as Company;
        pre.id = docs.docs[0].id;
        return pre;
      });
  }

  getCategories(companyID: string, optionID: string): Observable<Categories[]> {
    return this.afs
      .collection('rentalCategories', (ref) =>
        ref
          .where('companyID', '==', companyID)
          .where('optionID', '==', optionID)
          .where('isAvailable', '==', true)
          .orderBy('title', 'asc')
      )
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((data) => {
            let categories = data.payload.doc.data() as Categories;
            categories.id = data.payload.doc.id;
            return categories;
          });
        })
      );
  }

  getSmartWaiver(rentalid: string): Observable<any> {
    return this.afs
      .collection('rentals')
      .doc(rentalid)
      .collection('waiver')
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((data) => {
            let rentalFiles = data.payload.doc.data() as any;
            rentalFiles.id = data.payload.doc.id;
            return rentalFiles;
          });
        })
      );
  }
  //documents
  getCategorie(): Observable<any> {
    return this.afs
      .collection('rentalCategories')
      .doc(this.categoryID)
      .snapshotChanges()
      .pipe(
        map((changes) => {
          let data = changes.payload.data() as any;
          data.id = changes.payload.id;
          return data;
        })
      );
  }

  getIsTesting() {
    return this.afs
      .collection('companies')
      .doc('7JQQkZqjwxshPILXaoBf')
      .ref.get()
      .then((doc) => {
        const data = doc.data() as Company;
        data.id = doc.id;
        return data.isTesting;
      });
  }

  getCategoriePromise(): Promise<any> {
    return this.afs
      .collection('rentalCategories')
      .doc(this.categoryID)
      .ref.get()
      .then((doc) => {
        const data = doc.data() as any;
        data.id = doc.id;
        return data;
      });
  }

  getAllCategoriePromise(): Promise<any> {
    return this.afs
      .collection('rentalCategories')
      .ref.get()
      .then((docs) => {
        return docs.docs.map((doc) => {
          const data = doc.data() as any;
          data.id = doc.id;
          return data;
        });
      });
  }

  getCategoriePromiseByID(categoryID): Promise<any> {
    return this.afs
      .collection('rentalCategories')
      .doc(categoryID)
      .ref.get()
      .then((doc) => {
        const data = doc.data() as any;
        data.id = doc.id;
        return data;
      });
  }

  //   deleteRentals() {
  // //borra todos los rentals con companyId = I3ANuzttq4Qrvld5xuYb
  //     this.afs.collection('rentals').ref.where('companyID', '==', 'Di2f5vvTe8sTFbN0OSR3').get().then(docs => {
  //       docs.forEach(doc => {
  //         this.afs.collection('rentals').doc(doc.id).delete()
  //
  //       })
  //     })
  //     this.afs.collection('pdfPayments').ref.where('companyID', '==', 'Di2f5vvTe8sTFbN0OSR3').get().then(docs => {
  //       docs.forEach(doc => {
  //         this.afs.collection('pdfPayments').doc(doc.id).delete()
  //
  //       })
  //     })
  //
  //
  //   }

  getProductsByName(productName: string): Promise<any[]> {
    let query = this.afs
      .collection('products')
      .ref.where('productName', '==', productName)
      .where('companyID', '==', this._currentUserService.currentUser.companyId)
      .where('productSize', '!=', 'NA');

    return query.get().then((docs) => {
      return docs.docs.map((doc) => {
        const data = doc.data() as any;
        data.id = doc.id;
        return data;
      });
    });
  }

  getInventory(categoryID: string): Observable<ProductV0[]> {
    return this.afs
      .collection('products', (ref) =>
        ref
          .where('categoryID', '==', categoryID)
          .where('isActive', '==', true)
          //  .where("isMaintenance", "==", false)
          .where('isNeedingRepair', '==', false)
      )
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((data) => {
            let inventory = data.payload.doc.data() as ProductV0;
            inventory.id = data.payload.doc.id;
            return inventory;
          });
        })
      );
  }

  updateSizeNAProducts(productName: string) {
    this.afs
      .collection('products')
      .ref.where('productName', '==', productName)
      .get()
      .then((docs) => {
        docs.forEach((doc) => {
          this.afs.collection('products').doc(doc.id).update({
            productSize: 'NA',
          });
        });
      });
  }

  getInventoryPromise(
    categoryID: string,
    productLocationID: string
  ): Promise<ProductV0[]> {
    let query = this.afs
      .collection('products')
      .ref.where('categoryID', '==', categoryID)
      .where('isActive', '==', true)
      .where('isAvailable', '==', true)
      .where('productLocationID', '==', productLocationID);

    return query.get().then((docs) => {
      return docs.docs.map((doc) => {
        let data = doc.data() as ProductV0;
        data.id = doc.id;
        return data;
      });
    });
  }

  setOptionSelected(optionID: string) {
    this.optionSelected = optionID;
  }

  getOptionSelected(): Observable<string> {
    return new Observable((observer) => {
      observer.next(this.optionSelected);
    });
  }

  getRentalByID(id: string): Observable<Rental> {
    return this.afs
      .collection('rentals')
      .doc(id)
      .snapshotChanges()
      .pipe(
        map((changes) => {
          let data = changes.payload.data() as Rental;
          data.id = changes.payload.id;
          return data;
        })
      );
  }

  getRentalByIDPromise(rentalID: string): Promise<Rental> {
    return this.afs
      .collection('rentals')
      .doc(rentalID)
      .ref.get()
      .then((doc) => {
        const data = doc.data() as Rental;
        data.id = doc.id;
        return data;
      });
  }

  getRentalByPromise(rentalID: string): Promise<Rental | null> {
    return this.afs
      .collection('rentals')
      .doc(rentalID)
      .ref.get()
      .then((doc) => {
        if (doc.exists) {
          const data = doc.data() as Rental;
          data.id = doc.id;
          return data;
        } else {
          return null; // Document does not exist
        }
      });
  }

  getRentalDoc(productID: string): Promise<Rental> {
    return this.afs
      .collection('rentals')
      .doc(productID)
      .ref.get()
      .then((doc) => {
        const data = doc.data() as Rental;
        data.id = doc.id;
        return data;
      });
  }

  //this service is used to check if exists rentals with the product selected with date after today and before the end of the current month
  getRental(productID: string): Observable<Rental[]> {
    const firstDay = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      1
    );
    const lastDay = new Date(new Date().getFullYear(), 12, 0);
    return this.afs
      .collection('rentals', (ref) =>
        ref
          .where('productsID', 'array-contains', productID)
          .where('dayStart', '>=', firstDay)
          .where('dayStart', '<=', lastDay)
      )
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((data) => {
            let rental = data.payload.doc.data() as Rental;
            rental.id = data.payload.doc.id;
            let dataAny = data.payload.doc.data() as any;
            // if (dataAny){
            //   rental.dayStart = dataAny.dayStart.toDate()
            //   rental.dayEnd = dataAny.dayEnd.toDate()
            // }
            return rental;
          });
        })
      );
  }

  searchRentalByPaymentID(paymentID: any) {
    return this.afs
      .collection(
        'rentals',
        (ref) => ref.where('paymentID', '==', paymentID) //If maintenance is true
      )
      .snapshotChanges();
  }

  getRentalByProductName(productName: string): Observable<Rental[]> {
    const firstDay = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      1
    );
    const lastDay = new Date(new Date().getFullYear(), 12, 0);
    return this.afs
      .collection('rentals', (ref) =>
        ref
          .where('productsNamesMonths', 'array-contains', productName)
          .where('dayStart', '>=', firstDay)
          .where('dayStart', '<=', lastDay)
      )
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((data) => {
            let rental = data.payload.doc.data() as Rental;
            rental.id = data.payload.doc.id;
            let dataAny = data.payload.doc.data() as any;
            // if (dataAny){
            //   rental.dayStart = dataAny.dayStart.toDate()
            //   rental.dayEnd = dataAny.dayEnd.toDate()
            // }
            return rental;
          });
        })
      );
  }

  getRentalByProductNamePromise(productName: string): Promise<Rental[]> {
    const firstDay = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      1
    );

    let query = this.afs
      .collection('rentals')
      .ref.where('productsNamesMonths', 'array-contains', productName)
      .where('dayStart', '>=', firstDay);

    return query.get().then((docs) => {
      return docs.docs.map((doc) => {
        let data = doc.data() as Rental;
        data.id = doc.id;
        return data;
      });
    });
  }

  getProdructsByProductName(
    productName: string,
    whoCallme: string,
    productSize?: any
  ) {
    let ref = this.afs
      .collection('products')
      .ref.where('productName', '==', productName);
    if (productSize) {
      ref = ref.where('productSize', '==', productSize);
    }
    ref = ref.where('isActive', '==', true);
    ref = ref.where('isMaintenance', '==', false);

    return ref.get().then((res) => {
      return res.docs.map((r) => {
        let data = r.data() as ProductV0;
        data.id = r.id;
        return data;
      });
    });
  }

  checkAvailability(
    productID: string,
    dayStart: Date,
    dayEnd: Date
  ): Promise<boolean> {
    let query = this.afs
      .collection('rentals')
      .ref.where('productsID', 'array-contains', productID)
      .where('isCancelled', '==', false)
      .where('isComplete', '==', false)
      .where('isConfirmed', '==', true)
      .where('dayEnd', '>=', new Date(dayStart));
    return query.get().then((docs) => {
      if (docs.empty) {
        return true;
      } else {
        // for each doc in docs
        let available;
        // @ts-ignore
        docs.docs.forEach((doc) => {
          let data = doc.data() as Rental;

          if (data.dayStart.toDate() < new Date(dayEnd)) {
            available = false;
          }
        });
        return available == undefined;
      }
    });
  }
  checkAvailabilityPDay(
    productID: string,
    dayStart: Date,
    dayEnd: Date
  ): Promise<boolean> {
    let query = this.afs
      .collection('rentals')
      .ref.where('productsID', 'array-contains', productID)
      .where('isCancelled', '==', false)
      .where('isComplete', '==', false)
      .where('isConfirmed', '==', true)
      .where('rentalType', '==', 'byDay')
      .where('dayEnd', '>=', new Date(dayStart));
    return query.get().then((docs) => {
      if (docs.empty) {
        return true;
      } else {
        // for each doc in docs
        let available;
        // @ts-ignore
        docs.docs.forEach((doc) => {
          let data = doc.data() as Rental;

          if (data.dayStart.toDate() < new Date(dayEnd)) {
            available = false;
          }
        });
        return available == undefined;
      }
    });
  }
  checkAvailabilityWPending(
    productID: string,
    dayStart: Date,
    dayEnd: Date,
    rentalID: string
  ): Promise<boolean> {
    let query = this.afs
      .collection('rentals')
      .ref.where('productsID', 'array-contains', productID)
      .where('isCancelled', '==', false)
      .where('isComplete', '==', false)
      .where('isConfirmed', '==', false)
      .where('isPending', '==', true)
      .where('dayEnd', '>=', new Date(dayStart));
    return query.get().then((docs) => {
      if (docs.empty) {
        return true;
      } else {
        // for each doc in docs
        let available;
        // @ts-ignore
        docs.docs.forEach((doc) => {
          if (doc.id == rentalID) {
            return true;
          } else {
            let data = doc.data() as Rental;

            if (data.dayStart.toDate() < new Date(dayEnd)) {
              available = false;
            }
          }
        });
        return available == undefined;
      }
    });
  }

  checkAvailabilityWithoutRental(
    productID: string,
    dayStart: Date,
    dayEnd: Date,
    rentalID: string
  ): Promise<boolean> {
    let query = this.afs
      .collection('rentals')
      .ref.where('productsID', 'array-contains', productID)
      .where('isCancelled', '==', false)
      .where('isComplete', '==', false)
      .where('isConfirmed', '==', true)
      .where('dayEnd', '>=', dayStart);
    return query.get().then((docs) => {
      if (docs.empty) {
        return true;
      } else {
        // for each doc in docs
        let available = true;
        // @ts-ignore
        docs.docs.forEach((doc) => {
          let data = doc.data() as Rental;
          if (data.dayStart.toDate() < dayEnd && doc.id != rentalID) {
            available = false;
          } else {
          }
        });
        return available;
      }
    });
  }

  // tableData will contains the document items get from collection
  tableData: any[] = [];

  // save first document in snapshot of items received
  firstInResponse: any = [];

  // save last document in snapshot of items received
  lastInResponse: any = [];

  // keep the array of first document of previous pages
  prev_strt_at: any = [];

  // maintain the count of clicks on Next Prev button
  pagination_clicked_count = 0;

  // two buttons will be needed by which next data or prev data will be loaded
  // disable next and prev buttons
  disable_next: boolean = false;
  disable_prev: boolean = true;

  getRentalByDates(dates: DatesSelected): Observable<Rental[]> {
    //trae todas las rentas que se encuentren dentro del rango de fechas dado
    return this.afs
      .collection('rentals', (ref) =>
        ref
          .where('dayStart', '>=', dates.dateStart)
          .where('dayStart', '<=', dates.dateEnd)
      )
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((data) => {
            let rental = data.payload.doc.data() as Rental;
            rental.id = data.payload.doc.id;
            return rental;
          });
        })
      );
  }

  getTodayRentals(dates: DatesSelected, companyID: string) {
    return this.afs
      .collection('rentals', (ref) => {
        return ref
          .where('isCancelled', '==', false)
          .where('isComplete', '==', false)
          .where('isConfirmed', '==', true)
          .where('dayStart', '>=', dates.dateStart)
          .where('dayStart', '<=', dates.dateEnd)
          .where('companyID', '==', companyID);
      })
      .get()
      .toPromise()
      .then((snapshot) => {
        if (snapshot.empty) {
          return false;
        } else {
          return snapshot.docs.map((doc) => doc.data() as Rental);
        }
      });
  }

  getTodayProducts(dates: DatesSelected, companyID: string) {
    return this.afs
      .collection('rentals', (ref) => {
        return ref
          .where('isCancelled', '==', false)
          .where('isComplete', '==', false)
          .where('isConfirmed', '==', true)
          .where('dayStart', '>=', dates.dateStart)
          .where('dayStart', '<=', dates.dateEnd)
          .where('companyID', '==', companyID);
      })
      .get()
      .toPromise()
      .then((snapshot) => {
        if (snapshot.empty) {
          return false;
        } else {
          const productData: ProductToday[] = [];

          snapshot.forEach((doc) => {
            const rental = doc.data() as Rental;

            rental.products.map((p) => {
                productData.push({
                  product: p.productName,
                  totalValue: p.cost,
                  month: moment().format('YYYY-MM'),
                  rentalCount: 1,
                  size: p.productSize
                });

            });
          });

          // En este punto, rentalData contiene los resultados de la consulta en el formato deseado
          return productData;
        }
      });
  }

  getBookingByNumber(number): Observable<any[]> {
    return this.afs
      .collection('rentals', (ref) =>
        ref
          .where(
            'companyID',
            '==',
            this._currentUserService.currentUser.companyId
          )
          .where('rentalNumber', '==', number)
          .where('isConfirmed', '==', true)
      )
      .snapshotChanges();
  }

  getBookingByID(id: string): Observable<any> {
    return this.afs
      .collection('rentals', (ref) =>
        ref
          .where(
            'companyID',
            '==',
            this._currentUserService.currentUser.companyId
          )
          .where(firebase.firestore.FieldPath.documentId(), '==', id)
      )
      .snapshotChanges();
  }

  getBookingsSearchTermBegins(variant: string[]): Observable<any[]> {
    return this.afs
      .collection(
        'rentalSearch',
        (ref) =>
          ref
            .where(
              'companyID',
              '==',
              this._currentUserService.currentUser.companyId
            )
            .where('termBegins', 'array-contains-any', variant)
            .where('isConfirmed', '==', true)
      )
      .snapshotChanges();
  }

  getBookingsSearchTermContains(variant: string[]): Observable<any[]> {
    return this.afs
      .collection(
        'rentalSearch',
        (ref) =>
          ref
            .where(
              'companyID',
              '==',
              this._currentUserService.currentUser.companyId
            )
            .where('termContains', 'array-contains-any', variant)
            .where('isConfirmed', '==', true)
      )
      .snapshotChanges();
  }

  getProductById(id: string): Promise<any> {
    return this.afs
      .collection('products')
      .doc(id)
      .get()
      .toPromise()
      .then((doc) => {
        let product = doc.data() as ProductV0;
        product.id = doc.id;
        return product;
      });
  }

  getRentals(companyID: string): Observable<Rental[]> {
    const today = new Date();
    return this.afs
      .collection('rentals', (ref) =>
        ref.where('companyID', '==', companyID).orderBy('dayStart')
      )
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((data) => {
            let rental = data.payload.doc.data() as Rental;
            rental.id = data.payload.doc.id;
            var dataAny = data.payload.doc.data() as any;
            // rental.dayStart = dataAny.dayStart.toDate() //.dayStart["dayStart"].toDate()
            // rental.dayEnd = dataAny.dayEnd.toDate()
            return rental;
          });
        })
      );
  }

  getRentalsByCompanyID(companyID: string): Observable<Rental[]> {
    return this.afs
      .collection('rentals', (ref) => ref.where('companyID', '==', companyID))
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((data) => {
            let rental = data.payload.doc.data() as Rental;
            rental.id = data.payload.doc.id;
            return rental;
          });
        })
      );
  }

  getNotCancelledRentalsByCompanyID(companyID: string): Observable<Rental[]> {
    return this.afs
      .collection('rentals', (ref) =>
        ref
          .where('companyID', '==', companyID)
          .where('isCancelled', '==', false)
          .where('isConfirmed', '==', true)
      )
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((data) => {
            let rental = data.payload.doc.data() as Rental;
            rental.id = data.payload.doc.id;
            return rental;
          });
        })
      );
  }

  addRental(rental: Rental) {
    return this.afs.collection('rentals').add(rental);
  }

  setRentalIsPending(id: string, isPending: boolean) {
    return this.afs
      .collection('rentals')
      .doc(id)
      .update({ isPending: isPending });
  }

  addStripeTransaction(transaction) {
    return this.afs
      .collection('stripeTransactions')
      .doc(transaction.id)
      .set(transaction);
  }

  //Service to get the rentals of today and tomorrow where isActive is true
  getCurrentRentals(companyID: string): Observable<Rental[]> {
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return this.afs
      .collection('rentals', (ref) =>
        ref
          .where('companyID', '==', companyID)
          .where('isActive', '==', true)
          .where('dateStart', '>=', today)
          .where('dateStart', '<=', tomorrow)
      )
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((data) => {
            let rental = data.payload.doc.data() as Rental;
            rental.id = data.payload.doc.id;
            return rental;
          });
        })
      );
  }

  emmitEvent(key: string) {
    this.$emitter.emit(key);
  }

  confirmRentalByPaymentID(paymentID: string) {
    //look if some rental has this paymentID and return true if it does and false if it doesn't
    return this.afs
      .collection('rentals', (ref) => ref.where('paymentID', '==', paymentID))
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((data) => {
            let rental = data.payload.doc.data() as Rental;
            rental.id = data.payload.doc.id;
            return rental;
          });
        })
      );
  }

  getUpcomingRentals(fecha: Date) {}

  /**
   * Check if the userInfo.name attribute in the rental document matches the customer.name.
   * @param rentalID - The ID of the rental document.
   * @returns A boolean indicating if the userInfo.name is different from customer.name.
   */
  confirmRentalUserInfo(rentalID: string): Promise<boolean> {
    return this.afs
      .collection('rentals')
      .doc(rentalID)
      .get()
      .toPromise()
      .then((doc) => {
        if (doc.exists) {
          const rentalData = doc.data() as Rental;

          if (rentalData.userInfo) {
            const userInfoName = rentalData.userInfo.name
              ? rentalData.userInfo.name
              : '';
            const userInfoLastName = rentalData.userInfo.lastName
              ? rentalData.userInfo.lastName
              : '';
            const userInfoEmail = rentalData.userInfo.email
              ? rentalData.userInfo.email
              : '';
            const userInfoPhone = rentalData.userInfo.phone
              ? rentalData.userInfo.phone
              : '';
            const userInfoAddress = rentalData.userInfo.address
              ? rentalData.userInfo.address
              : '';
            const userInfoCity = rentalData.userInfo.city
              ? rentalData.userInfo.city
              : '';
            const userInfoZip = rentalData.userInfo.zip
              ? rentalData.userInfo.zip
              : '';
            const userInfoState = rentalData.userInfo.state
              ? rentalData.userInfo.state
              : '';
            const userInfoCountry = rentalData.userInfo.country
              ? rentalData.userInfo.country
              : '';

            return (
              userInfoName !== '' &&
              userInfoLastName !== '' &&
              userInfoEmail !== '' &&
              userInfoPhone !== '' &&
              userInfoAddress !== '' &&
              userInfoCity !== '' &&
              userInfoZip !== '' &&
              userInfoState !== '' &&
              userInfoCountry !== ''
            );
          } else {
            return false;
          }
        } else {
          return false; // No rental found with the given ID
        }
      })
      .catch((error) => {
        console.error('Error retrieving rental information:', error);
        throw error;
      });
  }

  updateRental(rental, id: string) {
    console.log(`updateRental(${id})`, rental)
    return this.afs.collection('rentals').doc(id).update(rental);
  }

  updateRentalAndReturnRental(rental: Rental, id: string): Promise<Rental> {
    // Perform the update operation
    return this.afs.collection('rentals').doc(id).update(rental)
      .then(() => {
        // If the update operation is successful, fetch the updated document
        return this.afs.collection('rentals').doc(id).get().toPromise()
          .then(doc => {
            // Return the updated document data
            return doc.data() as Rental;
          });
      });
  }

  // updateRentalAmounts only updates specific rental amounts
  updateRentalAmounts(rental: Rental, id: string): Promise<Rental> {
    const items = []
    if (rental.cartObj.items) {
      rental.cartObj.items.forEach((item) => {
        const change = {}
        if (item.adjustedPrice) {
          change['adjustedPrice'] = item.adjustedPrice
          items.push(change)
        }
      })
    }

    const update = {
      amountPending: rental.amountPending,
      amountPendingAbs: rental.amountPendingAbs || 0,
      amountPendingToRefund: rental.amountPendingToRefund || 0,
    }

    if (items.length > 0) {
      Object.assign(update, { cartObj: { items: items }})
    }

    if (rental.cost && rental.cost > 0) {
      Object.assign(update, { cost: rental.cost })
    }

    return this.afs.collection('rentals').doc(id).update(update).then(() => {
      // If the set operation is successful, fetch the updated document
      return this.afs.collection('rentals').doc(id).get().toPromise()
        .then(doc => {
          // Return the updated document data
          return doc.data() as Rental;
        });
    });
  }

  updateStripeTransaction(transaction) {
    return this.afs
      .collection('stripeTransactions')
      .doc(transaction.id)
      .update(transaction);
  }

  deleteProductRental(rental) {
    return this.afs
      .collection('rentals')
      .doc(rental.id)
      .update({ products: rental.products });
  }

  getCompanyWaiver(id: any) {
    return this.afs
      .collection('companies')
      .doc(id)
      .ref.get()
      .then((doc) => {
        const data = doc.data() as any;
        data.id = doc.id;
        return data;
      });
  }
  getWaiversByCompany(id: any) {
    /*return this.afs.collection('companies').doc(id).collection("waivers").snapshotChanges().pipe(map(changes => {
      return changes
    }))*/
    let query = this.afs
      .collection('companies')
      .doc(id)
      .collection('waivers')
      .ref.orderBy('createdDate', 'desc');

    return query.get().then((docs) => {
      return docs.docs.map((doc) => {
        let data = doc.data() as any;
        data.id = doc.id;
        return data;
      });
    });
  }
  getWaiversByProduct(productid) {
    /*return this.afs.collection('products').doc(productid).ref.get().then(doc => {
      const data = doc.data() as any;
      data.id = doc.id
      return data;
    })*/
    let query = this.afs.collection('products').doc(productid).ref;

    return query.get().then((doc) => {
      let data = doc.data() as any;
      data.id = doc.id;
      return data;
    });
  }
  getWaiverById(companyid, waiverid) {
    let query = this.afs
      .collection('companies')
      .doc(companyid)
      .collection('waivers')
      .doc(waiverid).ref;
    return query.get().then((doc) => {
      let data = doc.data() as any;
      data.id = doc.id;
      return data;
    });
  }
  //update products adding optionID

  updateProduct(id: any, optionID: any) {
    return this.afs
      .collection('products')
      .doc(id)
      .update({ optionID: optionID });
  }

  getAllCategories(companyID: string) {
    return this.afs
      .collection('rentalCategories', (ref) =>
        ref.where('companyID', '==', companyID)
      )
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((data) => {
            let category = data.payload.doc.data() as Categories;
            category.id = data.payload.doc.id;
            return category;
          });
        })
      );
  }

  getAllProductsByCompany(companyID: string) {
    let query = this.afs
      .collection('products')
      .ref.where('companyID', '==', companyID)
      .where('isActive', '==', true)
      .where('isMaintenance', '==', false);

    return query.get().then((docs) => {
      return docs.docs.map((doc) => {
        let data = doc.data() as ProductV0;
        data.id = doc.id;
        return data;
      });
    });
  }

  getAllProductsByOption(optionID: string): Promise<ProductV0[]> {
    let query = this.afs
      .collection('products')
      .ref.where('optionID', '==', optionID)
      .where('isActive', '==', true)
      .where('isMaintenance', '==', false);

    return query.get().then((docs) => {
      return docs.docs.map((doc) => {
        let data = doc.data() as ProductV0;
        data.id = doc.id;
        return data;
      });
    });
  }

  private countryData = ccsJSON;

  getCountries() {
    return this.countryData.getCountries();
  }

  getStatesByCountry(countryShotName: string) {
    return this.countryData.getStatesByShort(countryShotName);
  }

  getCitiesByState(country: string, state: string) {
    return this.countryData.getCities(country, state);
  }

  getStripePKey(isTesting) {
    if (isTesting) {
      return this.afs
        .collection('companies')
        .doc('7JQQkZqjwxshPILXaoBf')
        .ref.get()
        .then((doc) => {
          return doc.data()['stripeTestPKey'];
        });
    } else {
      return this.afs
        .collection('companies')
        .doc('7JQQkZqjwxshPILXaoBf')
        .ref.get()
        .then((doc) => {
          const data = doc.data() as any;
          return data.stripePublicKey;
        });
    }
  }
  getTransactions(rentalID: any) {
    return this.afs
      .collection('stripeTransactions', (ref) =>
        ref.where('rentalID', '==', rentalID)
      )
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((doc) => {
            let data = doc.payload.doc.data() as stripeTransaction;
            data.id = doc.payload.doc.id;
            return data;
          });
        })
      );
  }

  // SAVE RENTAL DATA
  saveRental(rental) {
    return this.afs.collection('rentals').add(rental);
  }
  updateRentalAPay(id, rental) {
    return this.afs.collection('rentals').doc(id).update(rental);
  }

  getRentalSubscription(rentalID) {
    return this.afs
      .collection('rentals')
      .doc(rentalID)
      .snapshotChanges()
      .pipe(
        map((doc) => {
          let data = doc.payload.data() as Rental;
          data.dayStart = new Date(data.dayStart.seconds * 1000);
          data.dayEnd = new Date(data.dayEnd.seconds * 1000);
          data.id = doc.payload.id;
          return data;
        })
      );
  }

  getStripeTransaction(stripeTransactionID): Promise<any> {
    return this.afs
      .collection('stripeTransactions')
      .doc(stripeTransactionID)
      .ref.get()
      .then((doc) => {
        let data = doc.data() as stripeTransaction;
        data.id = doc.id;
        return data;
      })
      .catch((err) => {
        console.error(`error getStripeTransaction(${stripeTransactionID})`, err)
        return {} as stripeTransaction
      });
  }

  addIsConfirmedToRentals() {
    return this.afs
      .collection('rentals')
      .ref.get()
      .then((docs) => {
        docs.forEach((doc) => {
          this.afs
            .collection('rentals')
            .doc(doc.id)
            .update({ isConfirmed: true });
        });
      });
  }

  getRefunds(rentalID) {
    return this.afs
      .collection('stripeTransactions', (ref) =>
        ref.where('rentalID', '==', rentalID).where('type', '==', 'refund')
      )
      .get()
      .toPromise()
      .then((docs) => {
        return docs.docs.map((doc) => {
          let data = doc.data() as stripeTransaction;
          data.id = doc.id;
          return data;
        });
      })
      .catch((err) => {
        console.error(`error getRefunds(${rentalID})`, err)
        return [{} as stripeTransaction]
      });
  }

  getCharges(rentalID: string) {
    return this.afs
      .collection('stripeTransactions', (ref) =>
        ref.where('rentalID', '==', rentalID)
      .where('type', 'in', ['charge', 'complete'])
      )
      .get()
      .toPromise()
      .then((docs) => {
        return docs.docs.map((doc) => {
          let data = doc.data() as stripeTransaction;
          data.id = doc.id;
          return data;
        });
      })
      .catch((err) => {
        console.error(`error getCharges(${rentalID})`, err)
        return [{} as stripeTransaction]
      });
  }

  getRefundsWEdit(rentalID) {
    return this.afs
      .collection('stripeTransactions', (ref) =>
        ref
          .where('rentalID', '==', rentalID)
          .where('type', '==', 'refund')
          .where('type2', '!=', 'editrental')
      )
      .get()
      .toPromise()
      .then((docs) => {
        return docs.docs.map((doc) => {
          let data = doc.data() as stripeTransaction;
          data.id = doc.id;
          return data;
        });
      });
  }

  getChargesWEdit(rentalID) {
    return this.afs
      .collection('stripeTransactions', (ref) =>
        ref
          .where('rentalID', '==', rentalID)
          .where('type', '==', 'charge')
          .where('type2', '!=', 'editrental')
      )
      .get()
      .toPromise()
      .then((docs) => {
        return docs.docs.map((doc) => {
          let data = doc.data() as stripeTransaction;
          data.id = doc.id;
          return data;
        });
      });
  }
  getMainPayment(rentalID) {
    return this.afs
      .collection('stripeTransactions', (ref) =>
        ref.where('rentalID', '==', rentalID).where('type', '==', 'rental')
      )
      .get()
      .toPromise()
      .then((docs) => {
        return docs.docs.map((doc) => {
          let data = doc.data() as stripeTransaction;
          data.id = doc.id;
          return data;
        });
      })
      .catch((err) => {
        console.error(`error getMainPayment(${rentalID})`, err)
        return [{} as stripeTransaction]
      });
  }

  getDeposit(rentalID) {
    return this.afs
      .collection('stripeTransactions', (ref) =>
        ref.where('rentalID', '==', rentalID).where('type', '==', 'deposit')
      )
      .get()
      .toPromise()
      .then((docs) => {
        return docs.docs.map((doc) => {
          let data = doc.data() as stripeTransaction;
          data.id = doc.id;
          return data;
        });
      })
      .catch((err) => {
        console.error(`error getDeposit(${rentalID})`, err)
        return [{} as stripeTransaction]
      });
  }

  getDepositPending(rentalID) {
    return this.afs
      .collection('stripeTransactions', (ref) =>
        ref
          .where('rentalID', '==', rentalID)
          .where('type', '==', 'deposit')
          .where('status', '==', 'requires_capture')
      )
      .get()
      .toPromise()
      .then((docs) => {
        return docs.docs.map((doc) => {
          let data = doc.data() as stripeTransaction;
          data.id = doc.id;
          return data;
        });
      });
  }
  getAllPaymentsSuccedeed(rentalID) {
    return this.afs
      .collection('stripeTransactions', (ref) =>
        ref.where('rentalID', '==', rentalID)
      )
      .get()
      .toPromise()
      .then((docs) => {
        return docs.docs.map((doc) => {
          let data = doc.data() as stripeTransaction;
          data.id = doc.id;
          return data;
        });
      });
  }
  setStripeInfo(isTest): Promise<any> {
    //set this._rentalService.stripeID and this._rentalService.stripePublicKey

    let promise = new Promise((resolve, reject) => {
      if (isTest) {
        this.stripeID = environment.stripe.defaultAccount;
        this.stripePublicKey = environment.stripe.key;
        resolve(true);
      } else {
        this.stripeID = this.stripeIDConstant;
        this.stripePublicKey = this.stripePublicKeyConstant;
        resolve(false);
      }
    });
    return promise;
  }

  addAffiliate(affiliate) {
    return this.afs.collection('affiliateClicks').add(affiliate);
  }

  updateShopComments(id: string, shopComments: any) {
    return new Promise((resolve, reject) => {
      this.productsCollection
        .doc(id)
        .update(shopComments)
        .then((resp) => {
          resolve({ success: true, id: resp });
        })
        .catch((err) => reject(err));
    });
  }

  searchProductID(id: any) {
    return this.afs
      .collection('products')
      .doc(id)
      .ref.get()
      .then((doc) => {
        const data = doc.data() as any;
        data.id = doc.id;
        return data;
      });
  }

  searchCategoryID(id: any) {
    return this.afs
      .collection('rentalCategories')
      .doc(id)
      .ref.get()
      .then((doc) => {
        const data = doc.data() as any;
        data.id = doc.id;
        return data;
      });
  }

  async getProductLocation(productLocationID: string) {
    const doc = await this.afs
      .collection('productLocations')
      .doc(productLocationID)
      .ref.get();
    const data = doc.data() as ProductLocation;
    data.id = doc.id;
    return data;
  }

  async getFirstSale(companyID) {
    return this.afs
      .collection('rentals', (ref) =>
        ref
          .where('companyID', '==', companyID)
          .orderBy('dayStart', 'asc')
          .limit(1)
      )
      .get()
      .toPromise()
      .then((docs) => {
        return docs.docs.map((doc) => {
          let data = doc.data();
          return data;
        });
      });
  }
  checkAvailabilityObservable(dayStart, dayEnd, companyID): Observable<any> {
    return this.afs
      .collection(
        'rentals',
        (ref) =>
          ref
            .where('companyID', '==', companyID)
            .where('isCancelled', '==', false)
            // .where("isComplete", "==", false)
            // iscomplete canot be false for this algo in order for dt and lt to still be processed before search range
            .where('isConfirmed', '==', true)
            .where('dayStart', '<=', dayEnd)
            .orderBy('dayStart') // makes processing easier
      )
      .snapshotChanges()
      .pipe(
        map((changes) => {
          return changes.map((action) => {
            let data = action.payload.doc.data();
            data['id'] = action.payload.doc.id;
            return data;
          });
        })
      );
  }


  // rentalsCollection = this.afs
  //   .collection('rentals')
  //   .ref.where(
  //     'companyID',
  //     '==',
  //     this._currentUserService.currentUser.companyId
  //   )
  //   .where('isCancelled', '==', false)
  //   .where('isConfirmed', '==', true);

  // processDocument(doc) {
  //   const { rentalNumber, isConfirmed, userInfo, companyID } = doc.data();

  //   if (
  //     rentalNumber != undefined &&
  //     userInfo != undefined &&
  //     userInfo.email != undefined &&
  //     userInfo.name != undefined &&
  //     userInfo.lastName != undefined &&
  //     userInfo.phone != undefined
  //   ) {
  //     const termContains = [];
  //     const termBegins = [];

  //     console.log('Data', rentalNumber, isConfirmed, userInfo);

  //     const processField = (fieldValue) => {
  //       fieldValue = fieldValue.toLowerCase(); // Convertir a minúsculas

  //       for (let i = 0; i < fieldValue.length; i++) {
  //         let term = '';
  //         for (let j = i; j < fieldValue.length; j++) {
  //           term += fieldValue[j];
  //           termContains.push(term);
  //           if (i === 0 && !termBegins.includes(term)) {
  //             termBegins.push(term);
  //           }
  //         }
  //       }
  //     };

  //     // Process rentalNumber, email, and userInfo fields
  //     let number = userInfo.phone;
  //     let space = number.indexOf(' ');
  //     let removeCountryCode = number.slice(space).replace(/-/g, '');
  //     processField(String(rentalNumber));
  //     processField(String(userInfo.email));
  //     processField(String(userInfo.name));
  //     processField(String(userInfo.lastName));
  //     processField(String(removeCountryCode.trim()));

  //     return {
  //       id: doc.id,
  //       termContains,
  //       termBegins,
  //       isConfirmed: isConfirmed === true,
  //       companyID,
  //     };
  //   } else {
  //     console.log('<-- Incomplete data -->');
  //   }

  //   return { resp: 'NOT DONE' };
  // }

  // async processRentalsCollection() {
  //   const snapshot = await this.rentalsCollection.get();
  //   const result = [];
  //   snapshot.forEach((doc: any) => {
  //     return this.transaction(doc);
  //   });
  //   return result;
  // }

  // transaction(doc) {
  //   // Obtains a reference to the collection in Firestore
  //   const idRental = doc.id;
  //   console.log('idRental', idRental);

  //   const path = `rentalSearch/${idRental}`;
  //   const docRef: AngularFirestoreDocument = this.afs.doc(path);
  //   const result = [];

  //   this.afs.firestore.runTransaction(async (transaction) => {
  //     try {
  //       // Check if the document already exists
  //       const _doc = await transaction.get(docRef.ref);

  //       if (_doc.exists) {
  //         // El documento existe, puedes acceder a sus datos
  //         const data = _doc.data();
  //         console.log('El documento existe:', data.id);
  //       } else {
  //         // El documento no existe, realiza alguna acción alternativa
  //         console.log('El documento no existe:', idRental);

  //         const res = this.processDocument(doc);
  //         console.log('RES', res);

  //         transaction.set(docRef.ref, res);

  //         result.push(res);
  //       }
  //     } catch (error) {
  //       console.error('Error en la transacción:', error);
  //     }
  //   });
  //   return result;
  // }

  // async runScript() {
  //   const result = await this.processRentalsCollection();

  //   result.forEach((r) => {});
  // }
}
