import { Component, EventEmitter, Input, Output, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { debounceTime, Subject, switchMap } from 'rxjs';

/* Services */
import { CartService } from 'src/app/services/cart.service';
import { TimeService } from 'src/app/services/time.service';
import { AvailabilityService } from 'src/app/services/availability.service';
import { WidgetService } from 'src/app/services/widget.service';
import { PricingService } from 'src/app/services/pricing.service';
import { InventoryPageService } from 'src/app/services/inventory-page.service';
import { CookieInteractionService } from 'src/app/v2/services/cookie-interaction.service';
import { PaymentsService } from 'src/app/services/payments.service';
import { ReservationService } from 'src/app/v2/services/reservation.service';

/* Models */
import { ProductOptionsInterface, WidgetInterface, WidgetType } from 'src/app/models/widget.model';
import { AvailabilityInterface } from 'src/app/models/availability.model';
import { Rental } from 'src/app/models/storage/rental.model';
import { Cart } from 'src/app/models/storage/cart.model';
import { CartItem, CartOperation } from 'src/app/models/cart.model';
import { Product } from 'src/app/models/storage/product.model';
import { ProductGroup } from 'src/app/models/storage/product-group.model';

/* Libraries */
import { DateTime } from 'luxon';
import Swal from 'sweetalert2';
import { InventoryPage } from 'src/app/models/storage/inventory-page.model';
import { DiscountCode } from 'src/app/v2/models/storage/discount-code.model';
import { ProductLocation } from 'src/app/models/storage/product-location.model';
import { ProductSizeType } from 'src/app/models/storage/product-size-type.model';
import { ProductSize } from 'src/app/v2/models/product-size.model';
import { Company } from 'src/app/models/storage/company.model';


@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent {

  // View Child
  @ViewChild('cartForm') cartForm: NgForm // Form

  // Inputs
  @Input() public cartObj: Cart; // Also used in availability query
  @Input() public cartWidgetList: WidgetInterface[];
  @Input() public companyID: string
  @Input() public companyName: string;
  @Input() public companyObj: Company;
  @Input() public discountCodeMap: { [key: string]: DiscountCode };
  @Input() public inventoryPageMap: { [key: string]: InventoryPage };
  @Input() public locationMap: { [key: string]: ProductLocation };
  @Input() public locationsArray: Array<any> // array of all locations for a given company
  @Input() public pmtLinkRentalDoc: Rental | null = null;
  @Input() public productGroupMap: { [key: string]: ProductGroup };
  @Input() public productGroupsCollection: Array<any>
  @Input() public productMap: { [key: string]: Product };
  @Input() public productsArray: Array<any> // array of products returned from a firebase collection
  @Input() public productSizesMap: { [key: string]: ProductSize };
  @Input() public productSizeTypesMap: { [key: string]: ProductSizeType };
  @Input() public templateID: string;
  @Input() public widgetArray: WidgetInterface[];
  @Input() public widgetMap: { [key: string]: WidgetInterface };

  // Outputs
  @Output() private cartItemEdit = new EventEmitter<any>();
  @Output() private cartToCustomerInfo = new EventEmitter<any>();
  @Output() private getCollectionUpdates = new EventEmitter<any>();
  @Output() private updateAvailabilityFromCart = new EventEmitter<AvailabilityInterface>();
  @Output() private updateCart = new EventEmitter<any>();

  // Booleans
  protected isLoading: boolean = true;
  protected updatingCart: boolean = false;
  protected hasOutdatedItems: boolean = false;
  protected hasUnavailableItems: boolean = false;
  protected deleteHasResponded: boolean = true;
  protected showInvalidPromoCodeMsg: boolean = false;
  protected availabilityDoneProcessing: boolean;
  protected cartWidgetsLoading: boolean = false;
  protected cartWidgetsValid: boolean = true;
  protected isReservationPayment: boolean = false;
  protected advenireWidgetValid: boolean = true;
  protected isLoadingPrice: boolean = true;

  // String
  protected discountCodeInput: string = "";

  // Numbers
  protected cost: number;
  protected pendingCost: number;
  protected promoCodeDiscountTotal: number;
  protected tipWidgetTotal: number;
  protected taxes: number;
  protected total: number = 0;

  // Arrays
  protected flagUserItems: any[] = [];

  // Objects
  private availability: AvailabilityInterface;
  protected groupInventoryPage = {};

  // Enums
  protected CartOperation = CartOperation; // So the html can access the enum
  protected WidgetType = WidgetType; // So the html can access the enum

  // Subject/ Promises
  private cartChangesSubject = new Subject<void>();
  private pendingUpdatePromise: Promise<void> | null = null;


  constructor(private cartService: CartService,
    private timeService: TimeService,
    private pricingService: PricingService,
    private availabilityService: AvailabilityService,
    private widgetService: WidgetService,
    private inventoryPageService: InventoryPageService,
    private cookieInteractionService: CookieInteractionService,
    private router: Router,
    private _paymentsService: PaymentsService,
    private _reservationService: ReservationService,
    private cdr: ChangeDetectorRef) { };

  async ngAfterViewInit() {


    if (this.cartObj?.items?.length > 0) {
      this.setWidgetProductAndAddonTotalCountOnCartItems();
      this.manageCartOperations(CartOperation.Init);
      await this.checkIfItemsStillBad();
    } else {
      this.isLoading = false; // loading is done because cart is empty
      this.cdr.detectChanges();
    }
  }

  ngOnInit() {
    // Set up for the cart subject to update the cart in the database
    this.cartChangesSubject.pipe(
      debounceTime(500), // Wait for 1000ms (1 second) of inactivity
      switchMap(() => {
        this.updateCart.emit(this.cartObj);
        // this.updatingCart = true;
        // Store the promise returned by updateCart
        this.pendingUpdatePromise = this.cartService.updateCart(
          this.cartObj.id,
          {
            items: this.cartObj.items,
            additionalComments: this.cartObj.additionalComments,
            promoApplied: this.cartObj.promoApplied,
            promocode: this.cartObj.promocode,
            cartWidgetList: this.cartObj.cartWidgetList
          }
        );
        return this.pendingUpdatePromise;
      })
    ).subscribe({
      next: () => {
        // this.updatingCart = false;
      },
      error: (err) => {
        console.error('Error updating cart:', err);
        // this.updatingCart = false;
      }
    });
  }


  protected manageCartOperations(operation: CartOperation): void {

    switch(operation) {

      case CartOperation.Init:
        if (this.pmtLinkRentalDoc) {
          this.initCartPaymentLink();
        }
        else {
          this.initOperations();
        }
        break;

      case CartOperation.Checkout:
        if (this.pmtLinkRentalDoc) {
          this.checkOutWithPmtLink();
        }
        else {
          this.attemptToCheckout();
        }
        break;

      case CartOperation.ValidateDiscount:
        if (!this.pmtLinkRentalDoc) {
          this.validifyDiscountCode();
        }
        else {
          this.cartService.cannotModifyCartSwalWarning();
        }
        break;

      case CartOperation.ClearDiscount:
        if (!this.pmtLinkRentalDoc) {
          this.clearDiscountCode();
        }
        break;

      case CartOperation.CommentInput:
        if (!this.pmtLinkRentalDoc) {
          this.onCartChange();
        }
        break;

      case CartOperation.CartWidgetInput:
        if (!this.pmtLinkRentalDoc) {
          this.onCartWidgetInputChange();
        }
        break;

      case CartOperation.CartProductWidgetInput:
        if (!this.pmtLinkRentalDoc) {
          this.onCartProductWidgetInputChange();
        }
        break;

      case CartOperation.ClearCart:
        this.clearCart();
        break;
    }
  }

  private async checkIfItemsStillBad(): Promise<void> {
    let foundOutdated = false;
    let foundUnavailable = false;

    await this.cartObj.items.forEach((item) => {
      if (item['unavailable']) {
        foundUnavailable = true;
      }

      if (item['cartItemOutdated']) {
        foundOutdated = true;
      }
    });

    if (foundOutdated) {
      this.hasOutdatedItems = true;
    }

    if (foundUnavailable) {
      this.hasUnavailableItems = true;
    }
  }

  private async initOperations(): Promise<void> {

    await this.getCartWidgetListData();
    this.validifyThatDiscountCodeIsStillValid();
    await this.runAvailabilityAlgo();
    this.getCartTotalPrice();
    await this.availabilityChecks();
    await this.compareAgainstDateModifiedTimestamps();
    this.isLoading = false;
    return
  }

  private initCartPaymentLink(): void {
    this.groupInventoryPage['widgetList'] = this.cartObj['cartWidgetList'];
    this.isLoading = false;
    this.availabilityDoneProcessing = true;
    this.cartObj.paymentID = this.pmtLinkRentalDoc.paymentID;
    this.cartObj.amountPending = this.pmtLinkRentalDoc.amountPending;
    this.getCartTotalPrice();
    this.isLoading = false;
    this.availabilityDoneProcessing = true;
    return
  }

  protected async clearDiscountCode(): Promise<void> {
    if (this.pmtLinkRentalDoc) { // If user doesn't have permission to modify cart - return
      this.cartService.cannotModifyCartSwalWarning();
      return;
    }
    this.updatingCart = true;
    this.cartObj.promoApplied = false;
    this.cartObj.promocode = "";
    this.promoCodeDiscountTotal = 0;
    this.tipWidgetTotal = 0;
    this.onCartChange();
    await this.getCartTotalPrice();
    this.updatingCart = false
  }


  protected clearAdditionalComments(): void {
    if (this.pmtLinkRentalDoc) { // If user doesn't have permission to modify cart - return
      this.cartService.cannotModifyCartSwalWarning();
      return;
    }

    this.cartObj.additionalComments = "";
    this.onCartChange();
  }

  private setWidgetProductAndAddonTotalCountOnCartItems(): void {
    this.cartObj.items.forEach((item) => {
      // Initialize totals for each item
      item.addonTotalwoProduct = item.addonPrice;
      item.totalCountAddons = 0;
      item.totalCountProducts = 0;
      item.addonTotalWProduct = 0;

      const widgetProducts = item.widgetList.filter(w => w.widgetType === WidgetType.product);

      item.widgetList.forEach((widget, indexWidget) => {
        switch (widget.widgetType) {
          case WidgetType.dropdown:
          case WidgetType.radios:
              const selectedAddon = widget.element.options.find(o => o.is_selected);
              if (selectedAddon) {
                  item.widgetList[indexWidget].selectedAddon = selectedAddon;
              }
              break;

          case WidgetType.product:
              if (!widget.element.isDropdown) {
                  widget.element.options.forEach(product => {
                      if (product.inputValue > 0) {
                          item.addonTotalWProduct += (widget.element.price * product.inputValue);
                          item.totalCountProducts += product.inputValue;
                          item.addonTotalwoProduct -= (widget.element.price * product.inputValue);
                      }
                  });
              } else {
                  const selectedAddon = widget.element.options.find(o => o.is_selected);
                  if (selectedAddon) {
                      item.widgetList[indexWidget].selectedAddon = selectedAddon;
                      item.addonTotalWProduct += selectedAddon.price;
                      item.totalCountProducts += 1;
                      item.addonTotalwoProduct -= selectedAddon.price;
                  }
              }
              break;

          default:
            item.totalCountAddons += 1;
            break;
        }
      });

      // Combine widget products
      const widgetDropProducts = widgetProducts.filter(product => product.selectedAddon !== undefined);
      const widgetQtyProducts = widgetProducts.filter(product => !product.element.isDropdown);
      item.widgetProduct = [...widgetDropProducts, ...widgetQtyProducts];
    });

    if (!this.pmtLinkRentalDoc) { // To stop swal error message fromm showing on initial page load for a payment link
      this.updateCartData({items: this.cartObj.items})
    }
  }

  private async getCartWidgetListData(): Promise<void> {
    this.groupInventoryPage['widgetList'] = this.cartObj['cartWidgetList'];
    // Make sure cart has all cart widget needed // Mainly used for development, to correct errors
    if (this.cartObj.items.length > 0 && this.cartObj['cartWidgetList'] && this.cartObj['cartWidgetList'].length == 0) {
      this.groupInventoryPage['widgetList'] = await this.inventoryPageService.getCartWidgetListData(this.cartObj['items'], [], this.inventoryPageMap, this.productGroupMap, this.cartObj.companyId);

      // If there are new widgets then make sure to get the correct data
      if (this.groupInventoryPage['widgetList'].length > 0) {
        // Get the saved widget info and product info
        this.groupInventoryPage = await this.widgetService.getSavedWidgetsInfo(this.groupInventoryPage, this.widgetArray);
        this.groupInventoryPage = await this.widgetService.getProductWidgetData(this.groupInventoryPage, this.productGroupMap);
        this.groupInventoryPage = await this.widgetService.getProductOptionSizes(this.groupInventoryPage, this.productSizesMap, this.productSizeTypesMap, this.companyID, true); // Dont update databse otherwise it will make the item seem "outdated" when it is not

        await this.groupInventoryPage['widgetList'].forEach(async widget => {
          if (widget['widgetType'] == WidgetType.product) {
            widget['element']['price'] = await this.widgetService.calculateProductWidgetPrice(widget['element'], this.cartObj['items'][0]['daySpan'], this.cartObj['items'][0]['selectedHours'], this.cartObj['items'][0]['is24Hrs']);
          }
        })
      }
    }
    return
  }


  private async compareAgainstDateModifiedTimestamps(): Promise<void> {
    let itemsOutdated = false;
    const promises = this.cartObj?.items.map(async (cartItem) => {
        cartItem['cartItemOutdated'] = false;

        if (this.timeService.convertTimestampToLuxon(cartItem.dayStart) < DateTime.now()) {
          cartItem['cartItemOutdated'] = true;
          this.hasOutdatedItems = true;
          itemsOutdated = true;
        }
        try {
          const dateAdded: Date = new Date(cartItem.dateAdded.seconds * 1000);

          const validators = [
            this.validateProductGroup(cartItem.parentId, cartItem, dateAdded),
            this.validateProductSizeType(this.productMap[cartItem.productId]?.productSizeTypeID, cartItem, dateAdded),
            this.validateInventoryPage(cartItem, dateAdded),
            this.validateSavedWidgetsAndProductWidgets(cartItem, dateAdded)
          ];


          // Execute all validators and track if any return a truthy value
          const results = await Promise.all(validators);
          if (results.some(result => result)) {
              itemsOutdated = true;
          }

        } catch (error) {
            console.error("Error occurred while processing cart item:", error);
        }
    });

    // Wait for all promises to resolve
    await Promise.all(promises);

    this.hasOutdatedItems = itemsOutdated;
  }


  private validateProductSizeType(productSizeType: string, cartItem: CartItem, dateAdded: Date): boolean {
    /* Check productSizeType lastModified (no need to check size collection) */
    if (productSizeType && this.productSizeTypesMap[productSizeType]?.lastModified) { // if no lastModified variable found - then size was established before variable added. Therefore - most likely older than cart item
      const lastModified = new Date(this.productSizeTypesMap[productSizeType].lastModified.seconds * 1000);

      // If the product group was updated since adding this item to the cart - mark as item having updates -> remove from cart
      if (lastModified > dateAdded) {
        // mark item as being incorrect / old - needs to be removed from cart in order to continue checkout
        cartItem.cartItemOutdated = true;
        return true;
      }
    }

    return false;
  }

  private validateProductGroup(productGroupID: string, cartItem: CartItem, dateAdded: Date): boolean {
    /* Check Product Groups */
    if (this.productGroupMap[productGroupID]?.lastModified) {
      // Carts are assumed to always have an addedDate
      const lastModified = new Date(this.productGroupMap[productGroupID].lastModified.seconds * 1000);

      // If the product group was updated since adding this item to the cart - mark as item having updates -> remove from cart
      if (lastModified > dateAdded) {
        // mark item as being incorrect / old - needs to be removed from cart in order to continue checkout
        cartItem.cartItemOutdated = true;
        return true;
      }
    }

    return false;
  }

  private validateInventoryPage(cartItem: CartItem, dateAdded: Date): boolean {
    const inventoryPageId = this.productGroupMap[cartItem.parentId]?.inventoryPageId;
    const inventoryPage = this.inventoryPageMap[inventoryPageId];
    if (inventoryPage?.lastModified) {
      const lastModified = new Date(inventoryPage.lastModified.seconds * 1000)
      if (lastModified > dateAdded) {
        cartItem.cartItemOutdated = true;
        return true;
      }
    }

    return false;
  }


  private validateSavedWidgetsAndProductWidgets(cartItem: CartItem, dateAdded: Date): boolean {
    let itemsOutdated = false;

    for (let widget of cartItem.widgetList) {
      if (widget.savedWidgetId !== "") { // if saved widget
        const lastModified = new Date(this.widgetMap[widget.savedWidgetId]?.lastModified.seconds * 1000);

        // look in savedWidget collection to see if an item has been updated
        if (lastModified > dateAdded) {
          cartItem.cartItemOutdated = true;
          itemsOutdated = true;
          return itemsOutdated; // Return early if outdated
        }
      }

      // Validate product widget
      if (widget.widgetType === WidgetType.product) {
        for (let option of widget.element.options) {

          // Loop through each option to see if one is selected
          if (option.inputValue > 0) {
            const groupId = widget.element.groupId;
            const sizeID = widget.element?.options[0]?.sizeID;

            // First validate the product group
            if (this.validateProductGroup(groupId, cartItem, dateAdded)) {
              itemsOutdated = true;
              return itemsOutdated; // Return early if validation is true
            }

            // Then validate the size
            for (const key in this.productSizeTypesMap) {
              if (this.productSizeTypesMap.hasOwnProperty(key)) {
                const value = this.productSizeTypesMap[key];
                const sortOrder = value.sortOrder; // Assuming sortOrder is an array in ProductSizeType

                if (Array.isArray(sortOrder) && sortOrder.includes(sizeID)) {
                  if (this.validateProductSizeType(key, cartItem, dateAdded)) {
                    itemsOutdated = true;
                    return itemsOutdated; // Return early if validation is true
                  }
                }
              }
            }
          }
        }
      }
    }
    return itemsOutdated;
  }


  protected async clearCart(): Promise<void> {
    // this.cartObj
    const prompt = await Swal.fire({
      title: $localize`Are you sure you want to clear all the items in your cart?`,
      text: $localize`All items and attached options will be removed from your cart.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: $localize`Yes, remove it`,
      cancelButtonText: $localize`Cancel`,
      reverseButtons: true,
      didClose: async () => {
        if (!prompt.isConfirmed) {
          return
        }

        try {
          const cartID = localStorage.getItem(`FM-cart-uuid-${this.templateID}`);
          this.cartObj.items = []; // Empty all content from the carts item arr
          // Update the cart widgets
          this.cartObj.cartWidgetList = await this.processUpdateToCartWidgets();
          // Cant use cartObj.id because it can be the id of the cart used to check out the rental (if visiting via payment link)... not necessarily cartID in localstorage
          // Cant use updateCartData because this can be handeled if the update fails
          this.cartService.updateCart(cartID, { items: this.cartObj.items, additionalComments: this.cartObj.additionalComments, promoApplied: this.cartObj.promoApplied, promocode: this.cartObj.promocode, cartWidgetList: this.cartObj.cartWidgetList });
          this.updateCart.emit(this.cartObj);

        }
        catch (err) {
          localStorage.removeItem(`FM-cart-uuid-${this.templateID}`);
        }

        // Check for Rental Cookie, if so, remove it
        const cookieName = this.cookieInteractionService.getPaymentLinkCookie(this.templateID);
        if (this.cookieInteractionService.getCookie(cookieName)) {
          this.cookieInteractionService.removeCookie(cookieName);

          const parts = this.router.url.split('/cart')[0].split('/');
          const bookId = parts[2]; // Assuming the bookId is always in this position
          window.location.href = `/book/${bookId}`; // Reloads to re-trigger onInit & to prevent dealing with confusing router structure in place within these components
        }
        else {
          await this.runAvailabilityAndCheckAgainstIt();
        }

        this.clearDiscountCode(); // no items exist in cart -> clear code
        this.clearAdditionalComments();

        this.pmtLinkRentalDoc = null;
        this.deleteHasResponded = true;
        this.cartWidgetsLoading = false;
      }
    })
  }


  protected async removeFromCart(cartItemID: string): Promise<void> {
    // Check if user has permission to modify the cart
    if (this.pmtLinkRentalDoc) {
      return this.cartService.cannotModifyCartSwalWarning();
    }

    const confirmation = await this.confirmRemoval();
    if (!confirmation) return;

    this.deleteHasResponded = false;

    const tempCartItems = this.cartObj.items.filter(item => item.cartItemID !== cartItemID);

    const updateSuccessful = await this.updateCartData( {
      items: tempCartItems,
      additionalComments: this.cartObj.additionalComments,
      promoApplied: this.cartObj.promoApplied,
      promocode: this.cartObj.promocode,
      cartWidgetList: this.cartObj.cartWidgetList,
    });

    if (updateSuccessful) {
      this.cartObj.items = tempCartItems;
      // Handle cart updates after removal
      if (this.cartObj.items.length === 0) {
        this.clearDiscountCode();
        this.clearAdditionalComments();
      }

      this.cartObj.cartWidgetList = await this.processUpdateToCartWidgets();
      this.getCartTotalPrice();
      await this.runAvailabilityAndCheckAgainstIt();

      // Reset flags
      this.hasOutdatedItems = false;
      this.hasUnavailableItems = false;
      this.checkIfItemsStillBad();
    }
    else {
      this.updateCartFailedWarningSwalDisplay()
    }

    this.deleteHasResponded = true;
    this.cartWidgetsLoading = false;

  }


  private async confirmRemoval(): Promise<boolean> {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary ml-2',
        cancelButton: 'btn btn-secondary',
      },
      buttonsStyling: false,
    });

    const result = await swalWithBootstrapButtons.fire({
      title: $localize`Are you sure you want to remove this item from your cart?`,
      text: $localize`All options attached to this product will also be removed.`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: $localize`Yes, remove it`,
      cancelButtonText: $localize`Cancel`,
      reverseButtons: true,
    });

    return result.isConfirmed;
  }


  private async updateCartData(newData: object): Promise<boolean> {
    const cartID = this.cartObj.id || localStorage.getItem(`FM-cart-uuid-${this.templateID}`);

    try {
      await this.cartService.updateCart(cartID, newData);
      this.updateCart.emit(this.cartObj);
      return true
    } catch (error) {
      this.updateCartFailedWarningSwalDisplay();
      console.error('Error updating cart:', error);
      return false
    }
  }


  private async runAvailabilityAndCheckAgainstIt(): Promise<void>{

    if (this.cartObj.items.length <= 0) {
      this.updateAvailabilityFromCart.emit(); // Emit an empty event, this will trigger the wrapper to run the availability
    }
    else { // has items in cart
      await this.runAvailabilityAlgo();
      await this.availabilityChecks();
      this.updateAvailabilityFromCart.emit(this.availability);
    }
  }

  private async processUpdateToCartWidgets(): Promise<WidgetInterface[]> {
    this.cartWidgetsLoading = true;
    let newWidgetList = [];
    newWidgetList = await this.inventoryPageService.getCartWidgetListData(this.cartObj['items'], [], this.inventoryPageMap, this.productGroupMap, this.cartObj.companyId);
    let newWidget = this.inventoryPageService.removeCartWidgetListData(newWidgetList, this.groupInventoryPage['widgetList']);
    this.groupInventoryPage['widgetList'] = newWidget;
    return newWidget;
  }

  /**
   * Check if discount code is valid
   */
  protected async validifyDiscountCode(): Promise<void> {

    // If code found in map -> it's active and can be applied
    if (this.discountCodeMap[this.cartObj.promocode.toLowerCase()]) {
      // Set promo code info in cart
      this.updatingCart = true;
      this.cartObj.promoApplied = true; // originally false
      this.cartObj.promocode = this.discountCodeMap[this.cartObj.promocode.toLowerCase()].discountTitle; // originally null

      // Swal code applied toaster
      const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        customClass: {
          popup: 'colored-toast'
        },
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

      Toast.fire({
        icon: 'success',
        title: $localize`Promo Code Applied!`,
      })
      // Update cart with new price
      this.cartObj.promoApplied = true;
      await this.getCartTotalPrice();
      this.updateCartData(this.cartObj);
      this.updatingCart = false;
    }
    else {
      this.showInvalidPromoCodeMsg = true;
    }
  }

  private validifyThatDiscountCodeIsStillValid(): void {
    if (this.cartObj.promoApplied) {
      if (this.discountCodeMap[this.cartObj.promocode.toLowerCase()] === undefined) {
        this.cartObj.promoApplied = false
        this.cartObj.promocode = null;
        this.updateCartData({ promoApplied: this.cartObj.promoApplied, promocode: this.cartObj.promocode });
      }
    }
  }

  private async getCartTotalPrice(): Promise<void> {
    try {
      this.total = null;
      let discountObj = this.getDiscountObject();
      const pricingResults = await this.pricingService.calculateCartTotal(this.cartObj, this.productGroupMap, discountObj);

      this.updateTotals(pricingResults);

      const reservationResults = await this._reservationService.getIfCartIsReservedAndAmountToPay(this.cartObj, this.productGroupMap, this.discountCodeMap);
      await this.updatePendingCost(reservationResults, pricingResults);
      this.isLoadingPrice ? this.isLoadingPrice = false : "";
    } catch (error) {
      console.error('Error calculating cart total price:', error);
    }
  }

  // Get discount object if applicable
  private getDiscountObject(): any {
    if (this.cartObj.promoApplied) {
      return this.discountCodeMap[this.cartObj.promocode.toLowerCase()];
    }
    return null;
  }

  // Update total price and other related fields
  private updateTotals(pricingResults: any): void {
    const {
      cartItemsTotal,
      cartWidgetsTotal,
      feeTotal,
      promoCodeDiscountTotal,
      tipWidgetTotal,
      taxTotal,
      subTotal
    } = pricingResults;
    // Calculate total, taxes, and cost
    this.total = (cartItemsTotal + cartWidgetsTotal);
    this.promoCodeDiscountTotal = promoCodeDiscountTotal;
    this.tipWidgetTotal = tipWidgetTotal;
    const subTotalWithDiscounts = ((cartItemsTotal + cartWidgetsTotal) - promoCodeDiscountTotal);
    this.taxes = feeTotal + taxTotal;
    this.cost = subTotalWithDiscounts + tipWidgetTotal + this.taxes;
  }

  // Update pending cost based on payment status
  private async updatePendingCost(reservationResults: any, pricingResults: any): Promise<void> {
    if (this.pmtLinkRentalDoc) {
      await this.handleWithPaymentLink(reservationResults);
    } else {
      await this.handleWithoutPaymentLink(reservationResults, pricingResults);
    }
  }
  async handleWithPaymentLink(reservationResult: any) {
    if (reservationResult.rentalHasReservation) { // If the rental has a reservation
      if (reservationResult.reservationPaid) { // If the reservation is paid
        this.pendingCost = this.pmtLinkRentalDoc.amountPending;
        return;
      }
      this.isReservationPayment = reservationResult.rentalHasReservation;
      this.pendingCost = reservationResult.reservationTotalToPay;
      return;
    }
    this.pendingCost = this.pmtLinkRentalDoc.amountPending;
  }
  // Update reservation status and adjust pending cost
  async handleWithoutPaymentLink(reservationResults: any, pricingResults: any) {
    if (reservationResults.rentalHasReservation) {
      this.isReservationPayment = reservationResults.rentalHasReservation;
      this.pendingCost = reservationResults.reservationTotalToPay;
      this.cartObj.amountPending = (reservationResults.amountPending - this.promoCodeDiscountTotal);
      return;
    }
    this.isReservationPayment = false;
  }

  private async runAvailabilityAlgo(): Promise<void> {
    // reset values
    this.availabilityDoneProcessing = false;

    this.availability = await this.availabilityService.runAvailabilityAlgorithm(
      this.timeService.convertTimestampToLuxon(this.cartObj.items[0].dayStart).setZone(this.cartObj.items[0].locationTimeZone), // timezone needed here - otherwise, items break in diff timezones in cart
      this.timeService.convertTimestampToLuxon(this.cartObj.items[0].dayEnd).setZone(this.cartObj.items[0].locationTimeZone), // timezone needed here - otherwise, items break in diff timezones in cart
      this.cartObj.items[0].locationID,
      this.companyID,
      this.locationsArray,
      this.productsArray,
      this.productGroupMap,
      this.inventoryPageMap,
      this.widgetMap,
      this.productMap,
      this.cartObj,
      )

    console.debug(`CartComponent.runAvailabilityAlgo`, this.availability)

    return
  }

  private async availabilityChecks(): Promise<void> {
    if (this.pmtLinkRentalDoc) { // If a rental is already created, no need to check availabilities
      this.availabilityDoneProcessing = true;
      return
    }

    await this.checkCartItemsAvailabilities();
    this.groupInventoryPage['widgetList'] = await this.inventoryPageService.getCartProductWidgetMaxesParsing(this.groupInventoryPage['widgetList'], this.availability);
    this.availabilityDoneProcessing = true;
    return
  }

  private checkCartItemsAvailabilities(): void {
    this.hasUnavailableItems = false;
    const currentCartItemIDs: string[] = this.cartObj.items.map(item => item?.productId);
    // Loop through each item in the cart
    for (let item of this.cartObj.items) {
      if (item.productId) {
        if (!this.checkProductIDAvailability(item['productId'], item['parentId'])) {
          this.findIDForCartItem(item, currentCartItemIDs);
        }
      }
      else {
        this.findIDForCartItem(item, currentCartItemIDs);
      }
    }
    return
  }

  // ID swapping
  private findIDForCartItem(item: CartItem, currentCartItemIDs: string[]): void {
    let availIDs: string[] = [];
    try {
      availIDs = this.availability.getGroupSizeIDsAvailable(item['parentId'], item['productSizeID']);
    }
    catch (error) {
      throw new Error(`Error occurred while checking availability for product group ID ${item['parentId']} and product size ID ${item['productSizeID']}: ${error}`);
    }

    let found = false;
    if (availIDs.length < 1) {
      this.markItemUnavailable(item);
    }
    else {
      // Loop through each available ID
      availIDs.forEach((ID) => {
        if (!found) { // Dont continue loop if an ID has been found
          if (!currentCartItemIDs.includes(ID)) {
            item.productId = ID;
            delete item.unavailable
            currentCartItemIDs.push(ID);
            found = true;
            return
          }
        }
      })
      // If no available ID was found, mark the item as unavailable
      if (!found) {
        this.markItemUnavailable(item);
      }
    }
  }

  private markItemUnavailable(item: any): void {
    item['unavailable'] = true;
    this.hasUnavailableItems = true;
  }

  private checkProductIDAvailability(productID: string, productGroupID: string): boolean {
    try {
      const isAvailable = this.availability.checkProductAvailability(productGroupID, productID);
      return isAvailable;
    } catch (error) {
      console.error(`Error occurred while checking availability for product ID ${productID} in product group ID ${productGroupID}:`, error);
      return false;
    }
  }


  private async checkProductWidgetAvailability(cartObj: Cart): Promise<boolean> {
    let itemsAvailable = true;

    for (const item of cartObj.items) {
      if (!item.unavailable && !item.cartItemOutdated) {
        for (const widget of item.widgetList) {
          if (widget.widgetType === WidgetType.product) {
            const isAvailable = await this.checkProductWidgetAvailabilityHelper(widget, item);
            if (!isAvailable) {
              itemsAvailable = false;
            }
          }
        }
      }
    }
    return itemsAvailable;
  }


  private async checkProductWidgetAvailabilityHelper(widget: WidgetInterface, item: CartItem): Promise<boolean> {
    let isAvailable = true;

    if ('options' in widget.element && ('groupId' in widget.element) && Array.isArray(widget.element.options)) {
      const options = widget.element.options as ProductOptionsInterface[];

      for (const option of options) {
        if (option.inputValue > 0) {
          const isAvailableResult = await this.inventoryPageService.checkAlgoProductQuantitiesParsing(
            widget.element.groupId,
            option.sizeID,
            this.availability
          );

          if (!isAvailableResult.passCheck) {
            this.flagUserItems.push(`- ${widget.element?.name}, ${option.size} x${Math.abs(isAvailableResult.amountUnavail)}`);
            item['unavailableWidget'] = true;
            widget['unavailable'] = true;
            isAvailable = false;
          }
        }
      }
    }
    return isAvailable;
  }

  private checkOutWithPmtLink(): void {
    this.cartToCustomerInfo.emit();
    this.updatingCart = false;
    this.isLoading = false;
  }


  protected async attemptToCheckout(): Promise<void> {

    // verify that item is above stripe minimum
    if (this.cost < 0.50) {
      this.stripeMinimumAmountWarningSwalDisplay();
      return
    }

    this.updatingCart = true;
    this.onCartChange(); // Guarentees the database cart is fully updated wiht local cart
    this.getCollectionUpdates.emit();
  }


  public async checkOutProcessing(): Promise<void> {
    try {
      await this.compareAgainstDateModifiedTimestamps();

      // Handle outdated items
      if (this.hasOutdatedItems) {
        await this.displayOutdatedItemsWarning();
        return;
      }

      // Validate discount code
      this.validifyThatDiscountCodeIsStillValid();

      // Run availability checks and widget validations
      await Promise.all([
        await this.runAvailabilityAlgo(),
        await this.availabilityChecks(),
        this.validateWidgets(),
        this.validateAdvenireWidget()
      ]);

      // Check widget availability and process checkout if applicable
      if (this.cartWidgetsValid) {
        await this.checkWidgetAvailabilityForCheckout();
      }

      if (this.isCartReadyForCheckout()) {
        await this.processCheckout();
      }

    } catch (error) {
      console.error('Error during checkout processing:', error);
      this.checkoutFailedWarningSwalDisplay();
    } finally {
      this.updatingCart = false;
      this.isLoading = false;
    }
  }

  // Helper functions
  private async displayOutdatedItemsWarning(): Promise<void> {
    await this.outdatedItemsWarningSwalDisplay();
  }

  private async validateWidgets(): Promise<void> {
    this.cartWidgetsValid = await this.widgetService.requiredWidgetsCheck(this.groupInventoryPage['widgetList']);
  }

  private validateAdvenireWidget(): void {
    const { dayStart, locationTimeZone } = this.cartObj.items[0];
    this.advenireWidgetValid = this.inventoryPageService.validateAdvenireWidgetInCart(dayStart, locationTimeZone, this.groupInventoryPage['widgetList']);
  }

  private isCartReadyForCheckout(): boolean {
    return !this.hasUnavailableItems && !this.hasOutdatedItems && this.cartWidgetsValid && this.flagUserItems.length === 0 && this.advenireWidgetValid;
  }

  private async processCheckout(): Promise<void> {
    this.cartObj.items = this.cartObj.items.map(item => this.cleanCartItem(item));

    if (!this.cartObj.items.some(item => item.unavailable || item.cartItemOutdated)) {
      const isUpdated = await this.updateCartData(this.cartObj);
      if (isUpdated) {
        this.cartToCustomerInfo.emit();
      }
    }
  }

  private cleanCartItem(item: CartItem): CartItem {
    if (item.productId === undefined) {
      return { ...item, unavailable: true };
    } else {
      const { unavailable, ...itemWithoutUnavailable } = item;
      return itemWithoutUnavailable;
    }
  }


  private async checkWidgetAvailabilityForCheckout(): Promise<void> {
    let productWidgetsAvailable = await this.checkProductWidgetAvailability(this.cartObj);

    if (productWidgetsAvailable) {
      // Get all Ids currently being used except for product widget Ids because we are going to re-assign all product widget IDs to avoid ID swapping
      let IDsUsed = [];
      IDsUsed = this.inventoryPageService.getAllCurrentRentalIDs(this.cartObj, true, false, false);
      // Assign Ids to product widgets
      await this.cartObj.items.forEach(async (item, ind) => {
        let result = await this.widgetService.assignProductWidgetIDsParsing(item['widgetList'], this.availability, IDsUsed);
        this.cartObj.items[ind]['widgetList'] = result.widgetList;
        IDsUsed = result.IDsUsed;
      })

      // Assign Id's to the cart widgets if there are product widgets
      this.groupInventoryPage['widgetList'] = await this.widgetService.assignProductWidgetIDsParsing(this.groupInventoryPage['widgetList'], this.availability, IDsUsed).widgetList;
      this.cartObj['cartWidgetList'] = this.groupInventoryPage['widgetList'];
    }
  }


  protected async editCartItem(ind: number): Promise<void> {
    if (this.pmtLinkRentalDoc) { // If user doesn't have permission to modify cart - return
      this.cartService.cannotModifyCartSwalWarning();
      return
    }

    let dayStart = this.timeService.convertTimestampToLuxon(this.cartObj.items[ind].dayStart).setZone(this.locationMap[this.cartObj.items[0]?.locationID]?.timezone);
    let dayEnd = this.timeService.convertTimestampToLuxon(this.cartObj.items[ind].dayEnd).setZone(this.locationMap[this.cartObj.items[0]?.locationID]?.timezone);
    this.cartItemEdit.emit({
      productGroupID: this.cartObj.items[ind].parentId, location: this.cartObj.items[ind].locationID, startDate: dayStart,
      endDate: dayEnd, cartItemID: this.cartObj.items[ind].cartItemID
    })
  }

  private async onCartWidgetInputChange(): Promise<void> {
    this.updatingCart = true;
    this.onCartChange();
    await this.getCartTotalPrice();
    this.updatingCart = false;
  }

  private async onCartProductWidgetInputChange(): Promise<void> {
    this.updatingCart = true;
    this.onCartChange();
    await this.getCartTotalPrice();
    await this.runAvailabilityAlgo();
    await this.availabilityChecks();
    this.updatingCart = false;
  }


  protected onCartChange(): void {
    this.cartChangesSubject.next();
  }

  get isCheckoutButtonDisabled(): boolean {
    return this.updatingCart ||
           this.hasOutdatedItems ||
           !this.deleteHasResponded ||
           !this.availabilityDoneProcessing ||
           this.hasUnavailableItems ||
           !this.cartForm.valid;
  }

  private stripeMinimumAmountWarningSwalDisplay(): void {
    Swal.fire({
      icon: 'error',
      title: $localize`Oh no! | The Minimum amount to checkout was not met`,
      text: $localize`Fleetmaid doesn't allow processing for totals lower than $0.50. To continue with checkout, please fill the cart to at least $0.50 and try again.`,
      confirmButtonText: $localize`OK`,
      customClass: {
        confirmButton: 'btn btn-danger'
      },
    });
    return
  }

  private outdatedItemsWarningSwalDisplay(): void {
    Swal.fire({
      icon: 'error',
      title: $localize`Outdated items were found in the cart`,
      text: $localize`Please remove them to continue with check out`,
      confirmButtonText: $localize`OK`,
      customClass: {
        confirmButton: 'btn btn-danger'
      },
    });
    return
  }


  private updateCartFailedWarningSwalDisplay(): void {
    Swal.fire({
      icon: 'error',
      title: $localize`Something went wrong`,
      text: $localize`There was an issue updating your cart. Please try again.`,
      confirmButtonText: $localize`OK`,
      customClass: {
        confirmButton: 'btn btn-danger'
      },
    });
    return
  }

  private checkoutFailedWarningSwalDisplay(): void {
    Swal.fire({
      icon: 'error',
      title: $localize`Something went wrong`,
      text: $localize`There was an issue checking out. Please try again.`,
      confirmButtonText: $localize`OK`,
      customClass: {
        confirmButton: 'btn btn-danger'
      },
    });
    return
  }

  private cartAddonsChangedWarningSwalDisplay(): void {
    Swal.fire({
      icon: 'warning',
      title: $localize`Changes to cart addons`,
      text: $localize`There have been some recent updates to the cart addons. Please check the new changes and try checking out again`,
      confirmButtonText: $localize`OK`,
      customClass: {
        confirmButton: 'btn btn-danger'
      },
    });
    return
  }

  ngOnDestroy(): void {
    // Wait for the cart update to complete before destroying the component
    if (this.pendingUpdatePromise) {
      this.pendingUpdatePromise.then(() => {
      }).catch(() => {
      console.error('Update failed before component destruction');
      });
    }
    // Clean up subscriptions and other resources
    this.cartChangesSubject.complete();
  }


} // End of class
